import { Locale } from '../common/enums';
import { User } from '../common/interfaces';
import { authClient } from '../http-clients/Auth.client';
import { loggingClient } from '../http-clients/Logging.client';
import usersClient from '../http-clients/Users.client';

class UserService {
    public static getInstance(): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    private static instance: UserService;

    private constructor() {}

    public async fetchCurrentUser(): Promise<User> {
        const user = await usersClient.getCurrentUser();

        // OwnershipTransferContainer expects email to be in lower case.
        // Might as well change it as soon as we receive it back from Smartsheet, so that it is consistently lower case in DV.
        if (user.email) {
            user.email = user.email.toLowerCase();
        }

        // Sanitize user's locale.
        user.locale = user.locale ? user.locale.replace('_', '-') : Locale.EN_US;

        // Sanitize user.isUserLicensed
        if (user.isUserLicensed === undefined) {
            user.isUserLicensed = false;
        }

        return user;
    }

    /**
     * Calls the api to delete the jwt and clears the local storage
     */
    public async handleLogOut(): Promise<void> {
        try {
            await authClient.logOut();
        } catch (error) {
            loggingClient.logError('UserService.ts', 'handleLogOut', error);
        } finally {
            // TODO: [DVK-0036: Federated Login] Remove after (how much?) time.
            this.clearLocalStorage();
        }
    }

    /**
     * Clears all the user's items from localStorage so that the user will become "unauthenticated"
     */
    // TODO: [DVK-0036: Federated Login] Remove after (how much?) time.
    public clearLocalStorage(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('user');
        localStorage.removeItem('userIsLicensed');
    }

    public getToken(): string {
        return localStorage.getItem('token') || '';
    }
}

export const userService = UserService.getInstance();
