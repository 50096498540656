import { useEffect } from 'react';

interface ResizeHeightOptions {
    resizeHeightCallback: (height: number) => void;
    targetRef: React.RefObject<HTMLElement> | undefined;
}

export const useResizeHeight = ({ resizeHeightCallback, targetRef }: ResizeHeightOptions) => {
    useEffect(() => {
        if (!targetRef || !targetRef.current) {
            // If targetRef or targetRef.current is falsy, clean up the effect.
            return () => {};
        }

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === targetRef.current) {
                    const height = entry.target.clientHeight;
                    resizeHeightCallback(height);
                    break;
                }
            }
        });

        resizeObserver.observe(targetRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [targetRef, resizeHeightCallback]);
};
