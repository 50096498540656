import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { MultiSelectV2 } from '@smartsheet/lodestar-core';
import { BaseOptionType } from '@smartsheet/lodestar-core/dist/esm/components/selectV2/types';
import { FALLBACK_I18N_LOCALE, ObjectType } from '../../common/enums';
import { MultiPicklistObjectValue, SelectV2Option } from '../../common/interfaces';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useResizeHeight } from '../../common/hooks/useResizeHeight';
import { mapToMultiPicklistOptions, mergeMultiSelectOptions } from '../../common/utils/GetOptions';
import { shortLocaleSelector } from '../../containers/Auth/Selectors';
import { INIT_HEIGHT, Z_INDEX_SELECT, calculateMaxMenuHeight } from './formFieldUtils';

export interface Props {
    dataClientId: string;
    inputIndex: number;
    options?: string[];
    onChange?: (value?: SelectV2Option[]) => void;
    readOnly?: boolean;
    placeholder?: string;
    validation?: boolean;
    setFocusRef?: (ref: Element | null) => void;
    isSettingsMode: boolean;
    value?: MultiPicklistObjectValue;
    detailsDataRef?: React.RefObject<HTMLElement>;
}

const FormFieldMultiPicklist = ({
    dataClientId,
    inputIndex,
    options,
    readOnly,
    placeholder,
    validation,
    setFocusRef,
    onChange,
    isSettingsMode,
    value,
    detailsDataRef,
}: Props) => {
    const locale = useSelector(shortLocaleSelector) ?? FALLBACK_I18N_LOCALE;
    const multiPicklistOptions =
        typeof options === 'object' && options !== null && options !== undefined ? mapToMultiPicklistOptions(options) : options;
    const values = value ? value.values : undefined;
    const selectedMultiSelectOptions = mapToMultiPicklistOptions(values);

    const [maxMenuHeight, setMaxMenuHeight] = React.useState<number>(INIT_HEIGHT);

    const resizeHeight = (height: number) => {
        setMaxMenuHeight(calculateMaxMenuHeight(height));
    };

    useResizeHeight({ resizeHeightCallback: resizeHeight, targetRef: detailsDataRef });

    const handleGetOptionKey = (option: SelectV2Option): string => {
        return option.label ? option.label : '';
    };

    const handleChangeMultiPickList = (changedSelectedOptions: BaseOptionType[]): void => {
        let selectedValue: SelectV2Option[] | undefined = [];
        if (Array.isArray(changedSelectedOptions) && changedSelectedOptions.length > 0) {
            selectedValue = {
                objectType: ObjectType.MULTI_PICKLIST,
                values: changedSelectedOptions.reduce((acc: string[], option: BaseOptionType) => {
                    acc.push(option.value as string);
                    return acc;
                }, []),
            } as unknown as SelectV2Option[];
        } else {
            selectedValue = undefined;
        }

        if (onChange) {
            onChange(selectedValue);
        }
    };

    const handleCreateOption = (newOption: string) => {
        const newOptionObject: SelectV2Option = {
            value: newOption || '',
            label: newOption || '',
        };

        if (onChange) {
            const updatedOptions = [
                ...(selectedMultiSelectOptions || []), // Initialize to empty array if undefined
                newOptionObject,
            ];
            handleChangeMultiPickList(updatedOptions);
        }
    };

    // Needed to use it to prevent Add {value} displayed when validation is enabled
    const onCreateOption = validation ? undefined : handleCreateOption;

    const allOptions = mergeMultiSelectOptions(multiPicklistOptions, selectedMultiSelectOptions);
    // get columnOptions matching selectedOptions' values
    // this is necessary because Lodestar SelectV2 checks if selected elements are part of the options by simple equality (===)
    const selectedSubset = allOptions.filter((a: SelectV2Option) => selectedMultiSelectOptions?.some((s: SelectV2Option) => s.label === a.label));
    return (
        <StyledSelectV2
            id={`mpl-${inputIndex}`}
            clientId={dataClientId}
            isClearable
            isSearchable={!isSettingsMode}
            isReadonly={readOnly}
            getOptionKey={handleGetOptionKey}
            options={allOptions}
            selectedOptions={selectedSubset}
            onChange={handleChangeMultiPickList}
            onCreateOption={onCreateOption}
            locale={locale}
            highlightFirstOptionAutomatically
            portalProps={Z_INDEX_SELECT}
            isSettingsMode={isSettingsMode}
            useFuzzySearch
            maxMenuHeight={maxMenuHeight}
        />
    );
};

export default FormFieldMultiPicklist;

// Needed to void the interaction with MultiSelectV2 in the Details Panel Layout settings
const isSettingsModeCss = (props: { isSettingsMode: boolean }) => css`
    pointer-events: ${props.isSettingsMode ? 'none' : 'auto'};
`;

const StyledSelectV2 = styled(MultiSelectV2)`
    ${isSettingsModeCss}
`;
