import styled from '@emotion/styled';
import { Checkbox, MultiSelectV2, TypeRampV2, colors, sizes } from '@smartsheet/lodestar-core';
import { BaseOptionType, OptionSpreadProps } from '@smartsheet/lodestar-core/dist/esm/components/selectV2/types';
import { FALLBACK_I18N_LOCALE } from '../../../common/enums';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { emptyFunction } from '../../../common/utils/EmptyFunction';
import { MultiSelectItem } from '../../../components/MultiSelect/MultiSelect.interface';
import { shortLocaleSelector } from '../../Auth/Selectors';
export interface Props {
    dataClientId: string;
    inputIndex?: number;
    options?: MultiSelectItem[];
    onChange?: (value: MultiSelectItem[]) => void;
    readOnly?: boolean;
    setFocusRef?: (ref: Element | null) => void;
    selectedItems?: BaseOptionType[];
    placeholder?: string;
    blankString?: string;
    allowCustom: boolean;
}

const FilterMultiSelect = ({ dataClientId, inputIndex, options, readOnly, onChange, selectedItems, blankString, allowCustom }: Props) => {
    const locale = useSelector(shortLocaleSelector) ?? FALLBACK_I18N_LOCALE;

    const mapToBaseOptionType = (item: MultiSelectItem): BaseOptionType => {
        return {
            id: item.id,
            value: item.value,
            label: item.label !== '' ? item.label : blankString || '',
            symbol: item.symbol,
        };
    };

    const mapToMultiSelectItem = (option: BaseOptionType): MultiSelectItem => ({
        value: option.value as string,
        label: option.label as string,
        symbol: option.symbol as string,
        isValid: true,
        controlIdPrefix: '',
        id: option.id as number,
    });

    const baseOptions: BaseOptionType[] = (options || []).map(mapToBaseOptionType);

    const allOptions = [...(selectedItems ?? []), ...baseOptions.filter((option) => !selectedItems?.some((s) => s.value === option.value))];

    const selectedSubset = allOptions.filter((a: BaseOptionType) => selectedItems?.some((s: BaseOptionType) => s.label === a.label));

    const handleGetOptionKey = (option: BaseOptionType): string => {
        return option.id as string;
    };

    const handleChangeMultiPickList = (changedSelectedOptions: BaseOptionType[]): void => {
        const selectedValue = changedSelectedOptions.length > 0 ? changedSelectedOptions.map(mapToMultiSelectItem) : undefined;
        onChange?.(selectedValue || []);
    };

    const handleCreateOption = (newOption: string) => {
        const newOptionObject: MultiSelectItem = {
            value: newOption,
            label: newOption,
            isValid: true,
            controlIdPrefix: '',
        };

        if (onChange) {
            const newOptions: BaseOptionType[] = [...(selectedItems || []), mapToBaseOptionType(newOptionObject)];
            handleChangeMultiPickList(newOptions);
        }
    };

    // Needed to use it to prevent Add {value} displayed when validation is enabled
    const onCreateOption = allowCustom ? handleCreateOption : undefined;

    const formattedOptionCustomRender = (
        option: BaseOptionType,
        index: number,
        highlightedIndex: number,
        optionSpreadProps: OptionSpreadProps<HTMLDivElement>,
        isOptionSelected: boolean
    ): React.ReactElement => {
        const isHighlighted = index === highlightedIndex;

        return (
            <StyledSelectSymbolMenu {...optionSpreadProps} isSelected={isHighlighted}>
                {/* We need to use emptyFunction because onClick is required for Lodestar Checkbox */}
                <StyledCheckbox onClick={emptyFunction} checkedState={isOptionSelected ? 'checked' : 'unchecked'} />
                {typeof option.symbol === 'string' && (
                    <SelectSymbol>
                        <img src={option.symbol?.toString()} alt={typeof option.label === 'string' ? option.label : ''} />
                    </SelectSymbol>
                )}
                <SelectLabel system="lodestar" variant="bodyText" lineClamp={1}>
                    {typeof option.label === 'string' && option.label !== '' ? option.label : blankString}
                </SelectLabel>
            </StyledSelectSymbolMenu>
        );
    };

    return (
        <StyledMultiSelect
            id={`mpl-${inputIndex || 0}`}
            clientId={dataClientId}
            isClearable
            isSearchable
            isReadonly={readOnly}
            getOptionKey={handleGetOptionKey}
            options={allOptions}
            selectedOptions={selectedSubset}
            onChange={handleChangeMultiPickList}
            onCreateOption={onCreateOption}
            locale={locale}
            formatOption={formattedOptionCustomRender}
            highlightFirstOptionAutomatically
            useFuzzySearch
        />
    );
};

export default FilterMultiSelect;

const StyledMultiSelect = styled(MultiSelectV2)`
    width: 100%;
`;

const StyledSelectSymbolMenu = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${sizes.xSmall}px;
    &:hover {
        background-color: ${colors.neutralLight20};
    }
    ${(props: { isSelected: boolean }) => props.isSelected && `background-color: ${colors.neutralLight20};`}
`;

const SelectSymbol = styled.div`
    margin-right: ${sizes.xSmall}px;
`;

const SelectLabel = styled(TypeRampV2)`
    text-align: center;
`;

const StyledCheckbox = styled(Checkbox)`
    margin-right: ${sizes.xSmall}px;
`;
