import * as immutable from 'immutable';
import { fromJS } from 'immutable';
import defaultIcon from '../../assets/images/toolbar/accountMenuIconDefault/default-profile-pic.png';
import { Actions, ActionTypes } from './Actions';

const emptyUser = fromJS({ email: '', firstName: '', lastName: '', profileImgUrl: defaultIcon, isUserLicensed: false, locale: '' });

const initialState = () =>
    fromJS({
        isAuthenticated: false,
        user: emptyUser,
    });

const reducer = (state = initialState(), action: Actions) => {
    switch (action.type) {
        case ActionTypes.LOG_IN:
            const user = action.payload;
            return state.withMutations((currentState: immutable.Map<string, any>) => {
                currentState.set('isAuthenticated', true);
                currentState.set(
                    'user',
                    fromJS({
                        admin: user.admin,
                        email: user.email,
                        firstName: user.firstName || '',
                        lastName: user.lastName || '',
                        profileImgUrl: user.profileImgUrl || '', // TODO: Update profileImgUrl to be profileImgId
                        isUserLicensed: user.isUserLicensed,
                        eligibility: user.eligibility,
                        locale: user.locale,
                        timeZone: user.timeZone,
                    })
                );
            });
        case ActionTypes.LOG_OUT:
            return state.withMutations((currentState: immutable.Map<string, any>) => {
                currentState.set('isAuthenticated', false);
                currentState.set('user', emptyUser);
            });
    }
    return state;
};

export default reducer;
