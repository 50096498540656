import styled from '@emotion/styled';
import { sizes, Tab, TabList, TabPanel, Tabs } from '@smartsheet/lodestar-core';
import { AsyncStatus } from '../../../common/enums';
import { DetailsPanelTabType } from '../../../common/enums/DetailsPanelTabType.enum';
import { Attachment, CellObjectValue, Discussion, FormFieldInterface, IPaginatedResult, RowData, SmartsheetUser } from '../../../common/interfaces';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutomationIds } from '../../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { SubmittedForm } from '../SubmittedForm';
import * as DetailsPanelActions from './Actions';
import AttachmentComponent from './Attachment/Attachment';
import { CellImages } from './CellImage/CellImages';
import Comment from './Comment/Comment';
import DetailsData from './DetailsData/DetailsData';
import { LoadingData } from './LoadingData';
import { mapSubmittedFormToFormFieldInterfaces } from './MapSubmittedFormToFormFieldInterfaces';
import { RowRemovedFromView } from './RowRemovedFromView';
import { detailsPanelShowModalSelector } from './Selectors';

export interface DetailsProps {
    viewId: string;
    rowId?: string;
    width: number;
    displayComments: boolean;
    addComments: boolean;
    displayAttachments: boolean;
    addAttachments: boolean;
    onChangeIsDirty: (isDirty: boolean) => void;
    smartsheetUsers: IPaginatedResult<SmartsheetUser>;
    isNewSubmission: boolean;
    form: FormFieldInterface[] | undefined;
    attachments: Attachment[] | undefined;
    discussions: Discussion[] | undefined;
    isLoading: boolean;
    detailsDataSaveStatus?: AsyncStatus;
    detailsDataSubmittedForm?: SubmittedForm;
    detailsPanelDescription?: string;
    getRowData: () => void;
    initialTab?: DetailsPanelTabType;
}

const getInitialTab = (isNewSubmission: boolean, initialTab?: DetailsPanelTabType): DetailsPanelTabType => {
    if (isNewSubmission) {
        return DetailsPanelTabType.DATA;
    }

    return initialTab || DetailsPanelTabType.DATA;
};

export const Details = (props: DetailsProps) => {
    const languageElements = useLanguageElements();
    const [activeTab, setActiveTab] = useState(getInitialTab(props.isNewSubmission, props.initialTab));
    const [detailsDataComponentIsDirty, setDetailsDataComponentIsDirty] = useState(false);
    const [commentComponentIsDirty, setCommentComponentIsDirty] = useState(false);

    const detailsPanelShowModal = useSelector(detailsPanelShowModalSelector);
    const dispatch = useDispatch();
    const rowIdRef = useRef(props.rowId);

    useEffect(() => {
        if (rowIdRef.current !== props.rowId) {
            rowIdRef.current = props.rowId;
            setActiveTab(getInitialTab(props.isNewSubmission, props.initialTab));
        }
    }, [props.initialTab, props.isNewSubmission, props.rowId]);

    const attachmentTotal = Array.isArray(props.attachments) ? props.attachments.length : 0;
    const discussionTotal = Array.isArray(props.discussions) ? props.discussions.length : 0;

    const handleTabClick = (detailsPanelTabType: DetailsPanelTabType) => {
        // Don't do anything if the user clicked on the current tab.
        if (activeTab === detailsPanelTabType) {
            return;
        }

        if (activeTab === DetailsPanelTabType.DATA && props.detailsDataSaveStatus === AsyncStatus.NOT_STARTED) {
            return;
        }

        // If the user is moving away from the details tab, and they have unsaved changes, prompt them to first save.
        // Or if the user is moving away from the comments tab, and they have unsaved changes, ask them to first save.
        // The Attachments panel doesn't get dirty. Uploading attachments is a single step.
        const detailsDataNeedsSaving = activeTab === DetailsPanelTabType.DATA && detailsDataComponentIsDirty;
        const commentsNeedSaving = activeTab === DetailsPanelTabType.COMMENTS && commentComponentIsDirty;
        if (detailsDataNeedsSaving || commentsNeedSaving) {
            dispatch(DetailsPanelActions.Actions.showModalDetailsPanel());
            return;
        }

        setActiveTab(detailsPanelTabType);
    };

    const handleDetailsDataChangeIsDirty = (isDirty: boolean) => {
        setDetailsDataComponentIsDirty(isDirty);

        props.onChangeIsDirty(isDirty || commentComponentIsDirty);
    };

    const handleCommentChangeIsDirty = (isDirty: boolean) => {
        setCommentComponentIsDirty(isDirty);

        props.onChangeIsDirty(detailsDataComponentIsDirty || isDirty);
    };

    const handleDetailsDataSave = (submittedForm: SubmittedForm, cellImages: CellImages) => {
        // This scrollTo readjusts display on mobile devices
        window.scrollTo(0, 0);

        const originalRowData: RowData = {
            form: props.form != null ? props.form : [],
            attachments: props.attachments,
            discussions: props.discussions,
        };

        if (props.rowId == null) {
            let mappedFormFields = mapSubmittedFormToFormFieldInterfaces(originalRowData.form, submittedForm, cellImages);

            // If no data is being saved in the new row, and we have cell images to attach,
            // then use the alt text for the cell images for the text values of the fields.
            // This will allow the new row to be saved, since otherwise reports make blank rows
            // inaccessible to the view, and then attaching the images to the cell in the new row would fail.
            if (mappedFormFields.length < 1 && cellImages.altText.size > 0) {
                const submittedFormForAltText: SubmittedForm = {};
                (cellImages.altText as Map<number, CellObjectValue>).forEach((altText, columnId) => (submittedFormForAltText[columnId] = altText));
                const emptyCellImages: CellImages = { attachedForUpload: new Map(), altText: new Map(), scheduledForRemoval: new Map() };
                mappedFormFields = mapSubmittedFormToFormFieldInterfaces(originalRowData.form, submittedFormForAltText, emptyCellImages);
            }

            // In the scenario where the initial insert request fails, the props.selectRowId will be populated with a temp ID. As a consequence, on
            // the retry of the insert a check is also performed to see if the row upsert has the isNewSubmission prop set to true.
            dispatch(DetailsPanelActions.Actions.insertViewRow({ viewId: props.viewId, submittedForm: mappedFormFields, cellImages }));
        } else {
            const mappedFormFields = mapSubmittedFormToFormFieldInterfaces(originalRowData.form, submittedForm, cellImages);
            dispatch(
                DetailsPanelActions.Actions.updateViewRow({
                    viewId: props.viewId,
                    rowId: props.rowId,
                    originalForm: originalRowData.form,
                    submittedForm: mappedFormFields,
                    cellImages,
                })
            );
        }

        // This stops a modal informing a user of the dirty state from appearing when a user tries to click on another row
        resetIsDirty();
    };

    const handleCommentAndAttachmentSaveComplete = (): void => {
        resetIsDirty();
        props.getRowData();
    };

    const resetIsDirty = () => {
        setDetailsDataComponentIsDirty(false);
        setCommentComponentIsDirty(false);
        props.onChangeIsDirty(false);
    };

    return (
        <DetailsContainer>
            <TitleContainer>
                <TitleStyled>{languageElements.DETAIL_PANEL_DETAILS}</TitleStyled>
                {props.detailsPanelDescription && <DescriptionStyled>{props.detailsPanelDescription}</DescriptionStyled>}
            </TitleContainer>
            <Tabs selectedId={activeTab} onChange={handleTabClick} onlyRenderActiveTabPanelChildren={true}>
                <TabListStyled aria-label={languageElements.DETAIL_PANEL_LABEL}>
                    <Tab id={DetailsPanelTabType.DATA} data-client-id={AutomationIds.DETAILS_TAB_DATA} data-testid={AutomationIds.DETAILS_TAB_DATA}>
                        {languageElements.DETAIL_PANEL_DATA}
                    </Tab>
                    {!props.isNewSubmission && props.displayAttachments && (
                        <Tab
                            id={DetailsPanelTabType.ATTACHMENTS}
                            data-client-id={AutomationIds.DETAILS_TAB_ATTACHMENTS}
                            data-testid={AutomationIds.DETAILS_TAB_ATTACHMENTS}
                        >
                            {languageElements.DETAIL_PANEL_ATTACHMENTS} ({attachmentTotal})
                        </Tab>
                    )}
                    {!props.isNewSubmission && props.displayComments && (
                        <Tab
                            id={DetailsPanelTabType.COMMENTS}
                            data-client-id={AutomationIds.DETAILS_TAB_COMMENTS}
                            data-testid={AutomationIds.DETAILS_TAB_COMMENTS}
                        >
                            {languageElements.DETAIL_PANEL_COMMENTS} ({discussionTotal})
                        </Tab>
                    )}
                </TabListStyled>
                {props.isLoading && <LoadingData />}
                {!props.isLoading && props.form === undefined && <RowRemovedFromView />}
                <TabPanelStyled>
                    {props.form !== undefined && (
                        <DetailsData
                            form={props.form}
                            smartsheetUsers={props.smartsheetUsers}
                            viewId={props.viewId}
                            rowId={props.rowId}
                            onChangeIsDirty={handleDetailsDataChangeIsDirty}
                            showModal={detailsPanelShowModal}
                            onSave={handleDetailsDataSave}
                            saveStatus={props.detailsDataSaveStatus}
                            submittedForm={props.detailsDataSubmittedForm}
                            isNewSubmission={props.isNewSubmission}
                            getRowData={props.getRowData}
                        />
                    )}
                </TabPanelStyled>
                {!props.isNewSubmission && props.displayAttachments && (
                    <TabPanelStyled>
                        <AttachmentComponent
                            viewId={props.viewId}
                            rowId={parseInt(props.rowId!, 10)}
                            attachments={props.attachments}
                            width={props.width}
                            addAttachments={props.addAttachments}
                            onAttach={handleCommentAndAttachmentSaveComplete}
                        />
                    </TabPanelStyled>
                )}
                {!props.isNewSubmission && props.displayComments && (
                    <TabPanelStyled>
                        <Comment
                            viewId={props.viewId}
                            rowId={parseInt(props.rowId!, 10)}
                            discussions={props.discussions}
                            width={props.width}
                            addComments={props.addComments}
                            onNewComment={handleCommentAndAttachmentSaveComplete}
                            onChangeIsDirty={handleCommentChangeIsDirty}
                            showModal={detailsPanelShowModal}
                        />
                    </TabPanelStyled>
                )}
            </Tabs>
        </DetailsContainer>
    );
};

const DetailsContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
`;

const TitleContainer = styled.div`
    padding: 15px;
    box-sizing: border-box;
`;

const TitleStyled = styled.h3`
    font-size: 18px;
    font-weight: 800;
    margin: 0 0 ${sizes.xSmall}px 0;
    height: 24px;
`;

const DescriptionStyled = styled.p`
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
`;

const TabListStyled = styled(TabList)`
    padding: 0 ${sizes.medium}px;
`;

const TabPanelStyled = styled(TabPanel)`
    display: flex;
    overflow: hidden;
    flex: 1;
    width: 100%;
    height: 100%;
`;
