import { AsyncStatus } from '../../../common/enums';
import { Actions, ActionTypes } from './Actions';
import { RowUpsert } from './RowUpsert';

export const INSERT_ROW_ID = 'insertRow';

export interface DetailsState {
    detailsPanelShowModal: boolean;
    selectedRowId?: string;
    rowUpserts: Record<string, RowUpsert[]>;
}

const initialState: DetailsState = {
    detailsPanelShowModal: false,
    selectedRowId: undefined,
    rowUpserts: {},
};

const reducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case ActionTypes.RESET_DETAILS_PANEL_STATE:
            return { ...initialState };
        case ActionTypes.SHOW_MODAL_DETAILS_PANEL:
            return { ...state, detailsPanelShowModal: true };
        case ActionTypes.CLOSE_MODAL_DETAILS_PANEL:
            return { ...state, detailsPanelShowModal: false };
        case ActionTypes.SELECT_ROW:
            return { ...state, selectedRowId: action.payload };
        case ActionTypes.UNSELECT_ROW:
            return { ...state, selectedRowId: undefined };
        case ActionTypes.INSERT_VIEW_ROW: {
            const { submittedForm } = action.payload;

            const rowUpserts = {
                ...state.rowUpserts,
                [INSERT_ROW_ID]: [
                    {
                        isNewSubmission: true,
                        status: AsyncStatus.NOT_STARTED,
                        submittedForm,
                    },
                ],
            };

            return {
                ...state,
                rowUpserts,
            };
        }
        case ActionTypes.UPDATE_VIEW_ROW: {
            const { rowId, submittedForm } = action.payload;

            const rowUpserts = {
                ...state.rowUpserts,
            };

            rowUpserts[rowId] = [
                ...(Array.isArray(state.rowUpserts[rowId]) ? state.rowUpserts[rowId] : []),
                {
                    isNewSubmission: false,
                    status: AsyncStatus.NOT_STARTED,
                    submittedForm,
                },
            ];

            return {
                ...state,
                rowUpserts,
            };
        }
        case ActionTypes.UPSERT_VIEW_ROW_IN_PROGRESS: {
            const { rowId, trackingId, updatedForm, isNewSubmission } = action.payload;

            const rowUpserts = { ...state.rowUpserts };

            if (isNewSubmission) {
                rowUpserts[rowId] = [{ ...rowUpserts[INSERT_ROW_ID][0], updatedForm, trackingId, status: AsyncStatus.IN_PROGRESS }];
                delete rowUpserts[INSERT_ROW_ID];
            } else {
                const copyRowUpserts = [...rowUpserts[rowId]];
                const lastRowUpsertIndex = copyRowUpserts.length - 1;
                copyRowUpserts[lastRowUpsertIndex] = {
                    ...copyRowUpserts[lastRowUpsertIndex],
                    updatedForm,
                    trackingId,
                    status: AsyncStatus.IN_PROGRESS,
                };
                rowUpserts[rowId] = copyRowUpserts;
            }

            return {
                ...state,
                rowUpserts,
            };
        }
        case ActionTypes.UPSERT_VIEW_ROW_SUCCESS: {
            const { rowId, trackingId } = action.payload;

            const rowUpserts = { ...state.rowUpserts };

            // Check to make sure upsert hasn't been cleared out by reset
            if (Object.keys(rowUpserts).length !== 0) {
                rowUpserts[rowId] = rowUpserts[rowId].map((rowUpsert: RowUpsert) => {
                    if (rowUpsert.trackingId !== trackingId) {
                        return rowUpsert;
                    }

                    return {
                        ...rowUpsert,
                        status: AsyncStatus.DONE,
                    };
                });
            }

            return {
                ...state,
                rowUpserts,
            };
        }
        case ActionTypes.UPSERT_VIEW_ROW_FAILURE: {
            const { rowId, error, trackingId } = action.payload;

            const rowIdKey = rowId ?? INSERT_ROW_ID;
            const rowUpserts = { ...state.rowUpserts };

            rowUpserts[rowIdKey] = rowUpserts[rowIdKey].map((rowUpsert: RowUpsert) => {
                if (rowUpsert.trackingId && rowUpsert.trackingId !== trackingId) {
                    return rowUpsert;
                }

                return {
                    ...rowUpsert,
                    status: AsyncStatus.ERROR,
                    error,
                };
            });

            return {
                ...state,
                rowUpserts,
            };
        }
        case ActionTypes.DISMISS_ERRORS: {
            const rowUpserts = { ...state.rowUpserts };

            // if rowUpsert has status = error, remove the whole rowId from rowUpserts
            Object.keys(rowUpserts).forEach((rowId) => {
                if (rowUpserts[rowId].some((rowUpsert: RowUpsert) => rowUpsert.status === AsyncStatus.ERROR)) {
                    delete rowUpserts[rowId];
                }
            });

            return {
                ...state,
                rowUpserts,
            };
        }
        case ActionTypes.DISMISS_ERRORS_FOR_ROW: {
            const rowId = action.payload;
            const rowUpserts = { ...state.rowUpserts };

            // if rowUpsert[rowId] has status = error, remove the errored upserts
            rowUpserts[rowId] = rowUpserts[rowId].filter((rowUpsert: RowUpsert) => rowUpsert.status !== AsyncStatus.ERROR);
            if (rowUpserts[rowId]?.length === 0) {
                delete rowUpserts[rowId];
            }

            return {
                ...state,
                rowUpserts,
            };
        }
        case ActionTypes.UPSERT_VIEW_ROW_REMOVE_DONE: {
            const { rowId } = action.payload;

            const rowUpserts = { ...state.rowUpserts };

            rowUpserts[rowId] = rowUpserts[rowId]?.filter((rowUpsert: RowUpsert) => rowUpsert.status !== AsyncStatus.DONE);

            return {
                ...state,
                rowUpserts,
            };
        }
    }
    return state;
};

export default reducer;
