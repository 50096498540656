import { ColumnType } from '../../../../common/enums';
import * as dv from '../../../../common/interfaces';
import 'reflect-metadata';
import * as gsFormattingUtility from '../../../../common/utils/GsFormattingUtility';
import { isValidDateString } from '../../../../common/utils/MomentDates';
import './DetailsData.css';

export const getCellObjectValueFromField = (formField: dv.FormFieldInterface, isNewSubmission: boolean) => {
    switch (formField.type) {
        /**
         * If the type is a multi-contact list, try and use the fields objectValue if present.
         * If objectValue is not present, check for a default value.
         *
         * If default value is present, and if it is an array, check if it contains current user.
         * If it does contain current user, replace the current user default value with the actual current user's information.
         *
         * If default value is present, and it's a string, check if it is an email.
         * (This happens if the defaults were set when the Smartsheet column was single-contact).
         * Convert email to array to get it to work with multi-contact.
         */
        case ColumnType.CONTACT_LIST:
        case ColumnType.MULTI_CONTACT_LIST:
            if (formField.objectValue != null) {
                return formField.objectValue;
            }

            if (isNewSubmission) {
                // Get multi contact object value. Handles two cases:
                // 1) if default was set for multi contact col
                // 2) if default was set for a single col that was changed to multi
                return formField.defaultValue != null ? formField.defaultValue : undefined;
            }
            break;

        case ColumnType.PICKLIST:
            return isNewSubmission ? formField.value ?? formField.defaultValue : formField.value;

        case ColumnType.MULTI_PICKLIST:
            if (formField.objectValue != null) {
                return formField.objectValue;
            }

            if (isNewSubmission) {
                return formField.defaultValue != null ? formField.defaultValue : undefined;
            }
            break;

        case ColumnType.DURATION:
            return isNewSubmission ? formField.value ?? formField.defaultValue : formField.value;

        case ColumnType.DATE:
            const valueToReturn = isNewSubmission ? formField.value ?? formField.defaultValue : formField.value;
            if (formField.validation && formField.defaultValue != null) {
                return isValidDateString(valueToReturn) ? valueToReturn : undefined;
            }
            return valueToReturn;

        case ColumnType.TEXT_NUMBER:
            const formFieldsObjectValue = isNewSubmission ? formField.defaultValue ?? formField.value : formField.value;
            return gsFormattingUtility.getFormattedValueForEditAll(formFieldsObjectValue, formField.format);

        case ColumnType.CHECKBOX:
            return isNewSubmission ? formField.value ?? formField.defaultValue : formField.value;

        default:
            if (formField.value !== null) {
                return formField.value;
            }
            return formField.defaultValue != null ? formField.defaultValue : undefined;
    }
};
