/* eslint @typescript-eslint/no-misused-promises:"off" */
import { Modal } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { connect } from 'react-redux';
import alertIcon from '../../assets/images/alert/icon-info-circle-bluebigger.svg';
import { HttpStatusCodes } from '../../common/enums';
import { FeatureFlag } from '../../common/interfaces';
import { UserAnalyticsGlobalContext } from '../../common/metrics/UserAnalyticsGlobalContext';
import { isInIframe } from '../../common/utils/IsInIframe';
import GenericModalContent from '../../components/Modal/Content/GenericModalContent';
import featureFlagsClient from '../../http-clients/FeatureFlagsClient';
import licensingClient from '../../http-clients/LicensingClient';
import { loggingClient } from '../../http-clients/Logging.client';
import { changeLanguage } from '../../language-elements/LanguageElements';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../language-elements/withLanguageElementsHOC';
import { userService } from '../../services/User.service';
import { Actions as ActionsApp } from '../App/Actions';
import { Actions } from './Actions';
import { AUTHENTICATE_URL } from './Constants';
import { User } from './interfaces/User';

export const ControlId = {
    TRY_AGAIN: 'auth-1',
};

interface DispatchProps {
    logIn: (user: User) => Actions;
    storeFeatureFlags: (features: FeatureFlag[]) => ActionsApp;
}

type AuthProps = DispatchProps & LanguageElementsProp;

enum SessionStatus {
    VALIDATING,
    VALID,
    INVALID,
    UNAUTHORIZED,
    UNAUTHORIZED_THIRD_PARTY_SITE,
}

/**
 * The Auth component first checks to see if the user has valid session cookies.
 * If the user's session is valid, we log then into Dynamic View.
 * Otherwise, we redirect the user to the SMAR endpoint to automatically start the OAuth flow
 */
class Auth extends React.Component<AuthProps, { sessionStatus: SessionStatus; disallowLoginProcess: boolean }> {
    public constructor(props: AuthProps) {
        super(props);

        // Check to see if the disallowLoginProcess flag is set.
        const urlParams = new URLSearchParams(window.location.search);
        const disallowLoginProcess = Boolean(urlParams.get('disallowLoginProcess'));

        // If it is set, store the value in state and remove from the url.
        if (disallowLoginProcess) {
            const url = new URL(window.location.toString());
            url.searchParams.delete('disallowLoginProcess');
            history.replaceState(null, '', url.toString());

            // TODO: Consider simplifying SessionAuthGuard.ts once we are confident that all DV tokens are expired.
            //  This line can be enabled to clean up user's locally stored token.
            // userService.clearLocalStorage();
        }

        // Set component state.
        this.state = { sessionStatus: SessionStatus.VALIDATING, disallowLoginProcess };
    }

    public async componentDidMount(): Promise<void> {
        return this.validateSession();
    }

    /**
     * This render method does nothing more than kick off a login process.
     * If we have determined that the user is not logged in (their session status is "unauthorized"), then start the login process. Do not start
     * the login process for other reasons. (We might be checking the user's session, or the user may have encountered an unrelated error.)
     * (If the session is valid, then we have already dispatched the login action.  App.tsx will re-render shortly.)
     */
    public render(): React.ReactNode {
        switch (this.state.sessionStatus) {
            case SessionStatus.INVALID:
                return this.renderTryAgainButton();

            case SessionStatus.UNAUTHORIZED_THIRD_PARTY_SITE:
                return this.renderEmbeddedSiteModal();

            case SessionStatus.UNAUTHORIZED:
                return this.initiateLoginProcess();

            // We don't render anything while server checks the user's session.
            // For valid sessions, this component doesn't need to render anything.
            // At a higher level, the app will re-render the appropriate components.
            case SessionStatus.VALIDATING:
            case SessionStatus.VALID:
                return null;
        }
    }

    private renderTryAgainButton(): React.ReactNode {
        return (
            <div className="callback-container">
                <div className="callback-content">
                    <h3>{this.props.languageElements.ERRORSTATE_CALLBACK_ERROR_TITLE}</h3>
                    <p>{this.props.languageElements.ERRORSTATE_CALLBACK_ERROR_MESSAGE}</p>
                    <button
                        data-client-id={ControlId.TRY_AGAIN}
                        tabIndex={-1}
                        onClick={() => this.setState({ disallowLoginProcess: false }, () => this.validateSession().catch((ignore) => {}))}
                    >
                        {this.props.languageElements.CALLBACK_TRY_AGAIN_BUTTON_TEXT}
                    </button>
                </div>
            </div>
        );
    }

    private renderEmbeddedSiteModal(): React.ReactNode {
        return (
            <Modal className={`modal`} isOpen={true} shouldCloseOnOverlayClick={false} shouldCloseOnEscapePress={false} width={190}>
                <GenericModalContent
                    hidePrimaryButton={true}
                    hideSecondaryButton={true}
                    onClickSecondaryButton={() => {}}
                    onClickPrimaryButton={() => {}}
                    title={this.props.languageElements.ERRORSTATE_CALLBACK_ERROR_EMBEDDED_THIRD_PARTY_SITE_TITLE}
                    message={this.props.languageElements.ERRORSTATE_CALLBACK_ERROR_EMBEDDED_THIRD_PARTY_SITE_MESSAGE}
                    icon={alertIcon}
                />
            </Modal>
        );
    }

    private async loadFeatureFlags() {
        const featureFlagsResponse = await featureFlagsClient.get();
        this.props.storeFeatureFlags(featureFlagsResponse);
    }

    private async validateSession(): Promise<void> {
        // Since the DV session cookie is HttpOnly, we need to make a request to the DV api to see if the user already has a valid DV session.
        try {
            // Issue a GET request to an auth endpoint to check the user's session information.
            // If the endpoint returns a 401 Unauthorized, the axios library will convert it and throw an error.
            const user: User = { ...(await userService.fetchCurrentUser()) };

            const shortLocale = user.locale ? user.locale.slice(0, 2) : undefined;
            if (shortLocale) {
                await changeLanguage(shortLocale);
                loggingClient.logInfo({
                    file: 'Auth.tsx',
                    message: 'Set user locale',
                    newLocale: shortLocale,
                });
            }
            // If user is not licensed, check for trial eligibility
            if (!user.isUserLicensed) {
                user.eligibility = await licensingClient.getEligibility();
            }

            // Set the user details used throughout the user's session for user analytics.
            // If a user had previously been logged in, their user details would be overridden
            UserAnalyticsGlobalContext.setUser(user);

            // Load the feature flags for the user
            await this.loadFeatureFlags();

            // If no error is thrown, then the user's session cookies were valid, and we can consider them logged into the application.
            this.props.logIn(user);

            // There's really no reason to set the state here, since the login action will already cause the app to re-render.
            // Just including this code for consistency with the code in the following catch-block that sets the state.
            this.setState({ sessionStatus: SessionStatus.VALID });
        } catch (error) {
            if (error.response && error.response.status === HttpStatusCodes.UNAUTHORIZED) {
                // Under certain circumstances, the login process would get stuck in an infinite loop. In the past,
                // this has been caused by incorrect HTTP codes or by users embedding Dynamic View in third-party sites.
                // We now support a disallowLoginProcess query parameter to help us avoid infinite login loops.
                if (!this.state.disallowLoginProcess) {
                    // The user's session is invalid. Set the component's state to
                    // kick off the Smartsheet OAuth flow for logging in the user.
                    this.setState({ sessionStatus: SessionStatus.UNAUTHORIZED });
                } else {
                    // When a user completes the login process, they will be redirected to Dynamic View with a
                    // disallowLoginProcess=true query parameter. If the call to fetch the current user still fails as
                    // UNAUTHORIZED despite the user successfully completing the Smartsheet login process, we need to
                    // avoid additional login attempts. The user has a valid Smartsheet session but for some reason
                    // we're unable to validate their Dynamic View session. Log this and display a warning page.

                    if (isInIframe()) {
                        // Embedding DV in a non-SMAR (*.smartsheet.com) site is NOT supported. If DV is embedded in a
                        // non-Smartsheet website, then the appropriate Dynamic View cookies will not be attached to
                        // HTTP requests. This is due to the fact that the S4DV and XSRF-TOKEN cookies have the SameSite
                        // attribute set to Strict. See the following website for more information on SameSite=Strict
                        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite#strict
                        //
                        // NOTE: It is not possible to verify if DV is embedded in an iFrame using the window.top.location
                        // because the parent window would have a different origin meaning the iframe is blocked from
                        // accessing window.top.location.
                        loggingClient.logError('Auth.tsx', 'logInUser', 'DV is embedded in a non-SMAR site');
                        this.setState({ sessionStatus: SessionStatus.UNAUTHORIZED_THIRD_PARTY_SITE });
                    } else {
                        // We're leaving this logging statement to provide continuity with the logging statement in Auth.tsx
                        loggingClient.logError('Auth.tsx', 'logInUser', 'DV cookie XSRF-TOKEN not available');
                        // After a while, we can probably remove the logging statement above and leave the logging statement below.
                        loggingClient.logError('Auth.tsx', 'logInUser', 'User is unauthorized by disallowLoginProcess flag is set');
                        this.setState({ sessionStatus: SessionStatus.INVALID });
                    }
                }
            } else {
                // Setting the session status to invalid will cause a try again button to render.
                this.setState({ sessionStatus: SessionStatus.INVALID });
            }
        }
    }

    private initiateLoginProcess(): React.ReactNode {
        // Kick off the auth flow in the current window.
        const originalUriWithParams = location.pathname + location.search;
        const originalUri: string = encodeURIComponent(originalUriWithParams || '');
        // Do not use React-Router's Redirect component here.  We need the browser to make an HTTP GET request, rather than changing routes in React.
        window.location.href = `${AUTHENTICATE_URL}${originalUri}`;
        return null;
    }
}

const mapDispatch = {
    logIn: Actions.logIn,
    storeFeatureFlags: ActionsApp.storeFeatureFlags,
};

export default withLanguageElementsHOC(connect<DispatchProps>(null, mapDispatch)(Auth));
