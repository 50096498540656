import { AsyncStatus } from '../../../common/enums';
import { createSelector } from 'reselect';
import { StoreState } from '../../../store';
import { INSERT_ROW_ID } from './Reducers';
import { RowUpsert } from './RowUpsert';

const detailsPanelShowModalSelector = (state: StoreState) => state.detailsPanel.detailsPanelShowModal;
const selectedRowIdSelector = (state: StoreState) => state.detailsPanel.selectedRowId;

const upsertsSelector = (state: StoreState) => state.detailsPanel.rowUpserts;

const rowUpsertsSelector = (state: StoreState, rowId: string) => upsertsSelector(state)[rowId];

const currentRowUpsertSelector = createSelector(upsertsSelector, selectedRowIdSelector, (rowUpserts, selectedRowId) => {
    return rowUpserts[selectedRowId ?? INSERT_ROW_ID];
});

const latestCurrentRowUpsertSelector = createSelector(currentRowUpsertSelector, (currentUpserts) => {
    if (!currentUpserts) {
        return;
    }
    return currentUpserts[currentUpserts.length - 1];
});

const selectOverallStatusForRow = createSelector([upsertsSelector, (state: StoreState, rowId: number) => rowId], (rowUpserts, rowId) =>
    getStatusForRow(rowUpserts[rowId])
);

const selectStatusForCurrentRow = createSelector(currentRowUpsertSelector, (currentUpserts) => getStatusForRow(currentUpserts));

const getStatusForRow = (rowUpserts: RowUpsert[] | undefined) => {
    if (!rowUpserts || rowUpserts.length === 0) {
        return undefined;
    } else {
        // if any of the upserts has an error, then the row is in error
        if (rowUpserts?.some((upsert) => upsert.status === AsyncStatus.ERROR)) {
            return AsyncStatus.ERROR;
        }
        // if some upserts are not started, then the row is not started
        if (rowUpserts?.some((upsert) => upsert.status === AsyncStatus.NOT_STARTED)) {
            return AsyncStatus.NOT_STARTED;
        }
        // if the every upsert was successful, then the row is done
        if (rowUpserts?.every((upsert) => upsert.status === AsyncStatus.DONE)) {
            return AsyncStatus.DONE;
        }
        // by default, the row is in progress
        return AsyncStatus.IN_PROGRESS;
    }
};

const selectStatusForView = createSelector(upsertsSelector, (upserts) => {
    // if there are no upserts, return undefined
    if (Object.keys(upserts).length === 0) {
        return undefined;
    }
    // if any of the upserts has an error, then the view is in error
    if (Object.values(upserts).some((rowUpserts) => rowUpserts.some((upsert) => upsert.status === AsyncStatus.ERROR))) {
        return AsyncStatus.ERROR;
    }
    // if some of the upserts are not started, then the view is not started
    if (Object.values(upserts).some((rowUpserts) => rowUpserts.some((upsert) => upsert.status === AsyncStatus.NOT_STARTED))) {
        return AsyncStatus.NOT_STARTED;
    }
    // if every upsert was successful, then the view is done
    if (Object.values(upserts).every((rowUpserts) => rowUpserts.every((upsert) => upsert.status === AsyncStatus.DONE))) {
        return AsyncStatus.DONE;
    }
    // by default, the view is in progress
    return AsyncStatus.IN_PROGRESS;
});

export {
    detailsPanelShowModalSelector,
    selectedRowIdSelector,
    currentRowUpsertSelector,
    rowUpsertsSelector,
    latestCurrentRowUpsertSelector,
    selectStatusForCurrentRow,
    selectOverallStatusForRow,
    selectStatusForView,
};
