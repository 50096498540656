import * as React from 'react';
import { ErrorInfo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { HttpStatusCodes } from '../../common/enums';
import { UserAnalyticsGlobalContext } from '../../common/metrics/UserAnalyticsGlobalContext';
import { createUrlPath, isRenderedOnSMARDomain } from '../../common/utils/urlUtils';
import Main from '../../components/Main';
import MainFallback from '../../components/Main/MainFallback';
import featureFlagsClient from '../../http-clients/FeatureFlagsClient';
import licensingClient from '../../http-clients/LicensingClient';
import { loggingClient } from '../../http-clients/Logging.client';
import { changeLanguage } from '../../language-elements/LanguageElements';
import { userService } from '../../services/User.service';
import { Actions } from '../Auth/Actions';
import Auth from '../Auth/Auth';
import { User } from '../Auth/interfaces/User';
import Login from '../Auth/Login';
import Logout from '../Auth/Logout';
import { isAuthenticatedSelector } from '../Auth/Selectors';
import { Actions as ActionsApp } from './Actions';
import { handleAppErrors } from './AppUtils';
import './Media.css';

// Note: For testing without the withRouter and connect HOC we export the App here
export const App: React.FC<RouteComponentProps<any>> = (props) => {
    const onSMARDomain = isRenderedOnSMARDomain();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(isAuthenticatedSelector);

    React.useEffect(() => {
        window.onerror = handleAppErrors;
    }, []);

    // the useEffect hook is used to load the user data and feature flags when the app is first loaded only
    React.useEffect(() => {
        if (onSMARDomain && !isAuthenticated) {
            loadUserData();
        }
    }, [onSMARDomain, isAuthenticated]);

    const loadFeatureFlags = async () => {
        const featureFlagsResponse = await featureFlagsClient.get();
        dispatch(ActionsApp.storeFeatureFlags(featureFlagsResponse));
    };

    const handleError = (error: Error, info: ErrorInfo) => {
        loggingClient.logError('App.tsx', 'handleError', error, { componentStack: info.componentStack });
    };

    const loadUserData = async (): Promise<void> => {
        // Since the DV session cookie is HttpOnly, we need to make a request to the DV api to see if the user already has a valid DV session.
        try {
            // Issue a GET request to an auth endpoint to check the user's session information.
            // If the endpoint returns a 401 Unauthorized, the axios library will convert it and throw an error.
            const user: User = { ...(await userService.fetchCurrentUser()) };

            const shortLocale = user.locale ? user.locale.slice(0, 2) : undefined;
            if (shortLocale) {
                await changeLanguage(shortLocale);
                loggingClient.logInfo({
                    file: 'App.tsx',
                    message: 'Set user locale',
                    newLocale: shortLocale,
                });
            }

            // If user is not licensed, check for trial eligibility
            if (!user.isUserLicensed) {
                user.eligibility = await licensingClient.getEligibility();
            }

            // Set the user details used throughout the user's session for user analytics.
            // If a user had previously been logged in, their user details would be overridden
            UserAnalyticsGlobalContext.setUser(user);

            // Load the feature flags for the user
            await loadFeatureFlags();

            // If no error is thrown, then the user's session cookies were valid, and we can consider them logged into the application.
            dispatch(Actions.logIn(user));
        } catch (error) {
            if (error.response?.status === HttpStatusCodes.UNAUTHORIZED) {
                // Redirect to SMAR log in screen as the user is unauthenticated
                const encodedPath: string = encodeURIComponent(location.pathname);

                // Encode query params with the `?` excluded
                const encodedQueryParams = encodeURIComponent(location.search.substring(1));

                window.location.replace(`/b/home?dlp=${encodedPath}&dlq=${encodedQueryParams}`);
            }
        }
    };

    if (onSMARDomain) {
        if (!isAuthenticated) {
            return null;
        }
        return (
            <BrowserRouter basename={createUrlPath()}>
                <ErrorBoundary FallbackComponent={MainFallback} onError={handleError}>
                    <Main />
                </ErrorBoundary>
            </BrowserRouter>
        );
    }

    return isAuthenticated ? (
        <Switch>
            {/* When embedded in a dashboard or an iFrame, we use a pop-up for the auth flow.
                At the end, we need to auto-close the window, so the user is returned to their original window. */}
            <Route
                exact={true}
                path="/autoClose"
                render={() => {
                    window.close();
                    return null;
                }}
            />
            <Route exact={true} path="/logout" component={Logout} />
            <Route path="/" component={Main} />
        </Switch>
    ) : (
        <Switch>
            <Route exact={true} path="/login" component={Login} />
            <Route component={Auth} />
        </Switch>
    );
};

export default withRouter(App);
