import classNames from 'classnames';
import * as React from 'react';
import { AutomationIds, AutomationTypes } from '../../../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';
import './DetailsData.css';
import { useSelector } from 'react-redux';
import { selectStatusForCurrentRow } from '../Selectors';
import { StoreState } from '../../../../store';
import { AsyncStatus } from '../../../../common/enums';

interface Props {
    isDirty: boolean;
    isValid: boolean;
    isSaving: boolean;
    onCancel: () => void;
    onSave: () => void;
}

export const ControlId = {
    SAVE: 'ddf-1',
    CANCEL: 'ddf-2',
};

const DetailsDataFooter: React.FC<Props> = (props) => {
    const languageElements = useLanguageElements();
    const status = useSelector<StoreState>((state) => selectStatusForCurrentRow(state));
    const disabledViaSaveError = status === AsyncStatus.ERROR;

    let savingControl = (
        <div>
            <button
                id="detailsDataFooterSaveBtn"
                onClick={() => props.onSave()}
                className="btn state-button btn-primary"
                disabled={!props.isValid || props.isSaving || disabledViaSaveError}
                data-client-id={ControlId.SAVE}
                tabIndex={props.isDirty ? 1 : -1}
            >
                {languageElements.ADMIN_PANEL_FOOTER_PRIMARY_BUTTON_TEXT}
            </button>
            <button
                id="detailsDataFooterCancelBtn"
                onClick={() => props.onCancel()}
                className="btn details-data-button btn-secondary"
                data-client-id={ControlId.CANCEL}
                tabIndex={props.isDirty ? 0 : -1}
            >
                {languageElements.ADMIN_PANEL_FOOTER_SECONDARY_BUTTON_TEXT}
            </button>
        </div>
    );

    if (props.isSaving) {
        savingControl = (
            <p
                className={'saving-edit-row-indicator'}
                data-client-type={AutomationTypes.PROGRESS_INDICATOR}
                data-client-id={AutomationIds.DETAILS_TAB_FOOTER}
            >
                {languageElements.SPINNER_SAVING_EDIT_OTHER_ROWS_LABEL}
            </p>
        );
    }

    const containerCss = classNames('details-data-footer', 'panel', { open: props.isDirty });

    return <div className={containerCss}>{savingControl}</div>;
};

export default DetailsDataFooter;
