import { EXPECTED_FORMAT_STRING_PARTS, FORMAT_CURRENCY_LOOKUP, MAX_FRACTION_DIGITS } from '../constants/Smartsheet.constants';
import { Locale } from '../enums';
import {
    appliedFormat,
    colorFormat,
    currencyFormat,
    dateFormatSMAR,
    decimalCount,
    fontFamily,
    fontSize,
    horizontalAlign,
    numberFormat,
    verticalAlign,
} from '../enums/Format.enum';
import { AppliedFormatType, CurrencyFormatType, CurrencySymbolType, FormatMapKeys, NumberFormatType } from '../enums/FormatTypes.enum';
import { Format } from '../interfaces';
import { SmartsheetFormatMap, SmartsheetFormatObject, SmartsheetNumberFormatObject } from '../interfaces/SmartsheetFormatObject.interface';
import { getNumValidDecimalPlaces, getNumberFromString, isStringNumber, safeMultiplyByPowerOf10, safeNumberToString } from './NumbersUtility';

class SmartsheetFormatUtility {
    public static getInstance(): SmartsheetFormatUtility {
        if (!SmartsheetFormatUtility.instance) {
            SmartsheetFormatUtility.instance = new SmartsheetFormatUtility();
        }
        return SmartsheetFormatUtility.instance;
    }

    private static instance: SmartsheetFormatUtility;

    public formatMap: SmartsheetFormatMap = {};

    private constructor() {
        this.generateFormatMap();
    }

    /**
     * Accepts a Smartsheet API Format String and Smartsheet Cell Value and
     * uses the format information derived from the string to return a value
     * that is 'properly' formatted according to the format string with the correct
     * currency symbol, decimal count, thousand separator, etc.
     *
     *  TODO: For Future Locale String Support:
     *        We can inject the locale via toLocaleString() or leave blank to rely on client browser locale.
     *        As of 30.11.18, explicit cast to US locale per spec.
     */
    public getFormattedNumericValue = (inputValue: number, formatString: Format | undefined): string => {
        const numericFormatObject = formatString ? this.getNumberFormatObject(formatString) : undefined;
        const maxAllowedDecimals = Math.min(MAX_FRACTION_DIGITS, getNumValidDecimalPlaces(inputValue));

        let formattedNumericValue = safeNumberToString(inputValue);

        if (numericFormatObject) {
            const maximumFractionDigits =
                numericFormatObject.decimalCount != null ? Math.min(maxAllowedDecimals, numericFormatObject.decimalCount) : maxAllowedDecimals;

            const minimumFractionDigits = numericFormatObject.decimalCount != null ? maximumFractionDigits : 0;

            switch (numericFormatObject.numberFormat) {
                case NumberFormatType.number:
                    formattedNumericValue = inputValue.toLocaleString(Locale.EN_US, {
                        maximumFractionDigits,
                        minimumFractionDigits,
                        useGrouping: numericFormatObject.thousandSeparator,
                    });

                    break;

                case NumberFormatType.percent:
                    formattedNumericValue =
                        safeMultiplyByPowerOf10(inputValue, 2).toLocaleString(Locale.EN_US, {
                            maximumFractionDigits,
                            minimumFractionDigits,
                            useGrouping: numericFormatObject.thousandSeparator,
                        }) + '%';

                    break;

                case NumberFormatType.currency:
                    const currencySymbol = numericFormatObject.currencyObject ? numericFormatObject.currencyObject.symbol : CurrencySymbolType.NONE;

                    const offsetSymbolRight = numericFormatObject.currencyObject ? numericFormatObject.currencyObject.offsetRight : false;

                    const currencyNegativeSign = inputValue < 0 ? '-' : '';

                    // NOTE: JPY never contains decimals per international standards and this is reflected in app-core
                    const currencyDisplayValue = Math.abs(inputValue).toLocaleString(Locale.EN_US, {
                        maximumFractionDigits: currencySymbol === CurrencySymbolType.JPY ? 0 : maximumFractionDigits,
                        minimumFractionDigits: currencySymbol === CurrencySymbolType.JPY ? 0 : minimumFractionDigits,
                        useGrouping: numericFormatObject.thousandSeparator,
                    });
                    formattedNumericValue = offsetSymbolRight
                        ? currencyNegativeSign + currencyDisplayValue + currencySymbol
                        : currencyNegativeSign + currencySymbol + currencyDisplayValue;

                    break;

                default:
                    formattedNumericValue = Number(formattedNumericValue).toLocaleString(Locale.EN_US, {
                        maximumFractionDigits,
                        minimumFractionDigits,
                        useGrouping: numericFormatObject.thousandSeparator,
                    });

                    break;
            }
        } else {
            formattedNumericValue = Number(formattedNumericValue).toLocaleString(undefined, {
                maximumFractionDigits: maxAllowedDecimals,
                minimumFractionDigits: 0,
                useGrouping: false,
            });
        }
        return formattedNumericValue;
    };

    /**
     * Convenience Method: Takes in a Smartsheet API Format String
     * and converts it into a user friendly number formatting object.
     */
    public getNumberFormatObject = (formatString: Format): SmartsheetNumberFormatObject => {
        const fullFormatObject = this.getFormatObjectFromString(formatString);
        const currencyType = fullFormatObject.currencyFormat ? fullFormatObject.currencyFormat : CurrencyFormatType.NONE;
        return {
            currencyObject: FORMAT_CURRENCY_LOOKUP[currencyType],
            numberFormat: fullFormatObject.numberFormat,
            decimalCount: fullFormatObject.decimalCount
                ? Number(this.formatMap[FormatMapKeys.decimalCount].enumSet[fullFormatObject.decimalCount])
                : undefined,
            thousandSeparator: fullFormatObject.thousandSeparator === AppliedFormatType.on,
        };
    };

    /**
     * Returns a number if the number can be converted to a Smartsheet number and modifies it as needed based on a formatString
     * Treats scientific notation as a string on input, removes leading apostrophes unless isDefaultValueDefinition
     * is specified the value is a numeric string.
     */
    public getNumberFromInputAndFormatString = (
        input: string | number,
        formatString?: Format,
        isDefaultValueDefinition = false,
        skipGettingNumberFromString = false
    ): string | number => {
        const valueToGetNumberFrom = input;
        let numberFromInput;
        if (typeof valueToGetNumberFrom === 'string') {
            // value should be a string
            const inputWithoutApostrophe = this.removeSpecificLeadingCharacter(valueToGetNumberFrom, "'");
            if (inputWithoutApostrophe !== valueToGetNumberFrom) {
                // if we are not saving a default value, the leading apostrophe is removed
                return isDefaultValueDefinition ? valueToGetNumberFrom : inputWithoutApostrophe;
            }

            // Skip getting the number and formatting string. This is used primarily for rendering the input string in a component allowing for
            // spaces that would otherwise be trimmed
            if (skipGettingNumberFromString) {
                return valueToGetNumberFrom;
            }

            numberFromInput = getNumberFromString(valueToGetNumberFrom);
        } else if (typeof valueToGetNumberFrom === 'number') {
            numberFromInput = valueToGetNumberFrom;
        }

        // input is not of type number and is not a string number
        if (numberFromInput === undefined) {
            return valueToGetNumberFrom;
        }

        if (formatString) {
            const numberFormatObject = this.getNumberFormatObject(formatString);

            // Get value if format is percent
            if (numberFormatObject && numberFormatObject.numberFormat === NumberFormatType.percent) {
                numberFromInput = safeMultiplyByPowerOf10(numberFromInput, -2);
            }
        }

        // Check for too many decimals
        const maxAllowedDecimals = getNumValidDecimalPlaces(numberFromInput);

        return parseFloat(numberFromInput.toFixed(maxAllowedDecimals));
    };

    /**
     * Returns a number if the number can be converted to a Smartsheet number and modifies it as needed based on a formatString
     * Treats scientific notation as a string on input, removes leading apostrophes unless isDefaultValueDefinition
     * is specified the value is a numeric string.
     */
    public getNumberFromTrimmedInputAndFormatString = (input: string | number, formatString?: Format, isDefaultValueDefinition = false) => {
        let valueToGetNumberFrom = input;
        if (typeof valueToGetNumberFrom === 'string') {
            valueToGetNumberFrom = valueToGetNumberFrom.trim();
        }

        return this.getNumberFromInputAndFormatString(valueToGetNumberFrom, formatString, isDefaultValueDefinition);
    };

    /**
     * Returns an array of classnames based on a formatString used in cell formatting
     * CSS associated with these classes is in CellFormats.css
     */
    public getCellFormatClassNames = (formatString?: Format): string[] => {
        const classNames: string[] = [];
        if (formatString) {
            const fullFormatObject = this.getFormatObjectFromString(formatString);
            if (fullFormatObject.bold === AppliedFormatType.on) {
                classNames.push('bold');
            }
            if (fullFormatObject.italic === AppliedFormatType.on) {
                classNames.push('italic');
            }
            if (fullFormatObject.underline === AppliedFormatType.on) {
                classNames.push('underline');
            }
            if (fullFormatObject.strikethrough === AppliedFormatType.on) {
                classNames.push('strikethrough');
            }
            if (fullFormatObject.backgroundColor) {
                classNames.push(`background-color-${this.removeSpecificLeadingCharacter(fullFormatObject.backgroundColor, '#')}`);
            }
            if (fullFormatObject.textColor) {
                classNames.push(`text-color-${this.removeSpecificLeadingCharacter(fullFormatObject.textColor, '#')}`);
            }
        }

        return classNames;
    };

    /**
     * Returns a value formatted for editing
     */
    public getFormattedValueForEdit = (input: string | number, formatString?: Format, isDefaultValue = false): string | number => {
        let valueToBeFormatted = input;

        // Add leading apostrophe if needed
        if (typeof valueToBeFormatted === 'string') {
            valueToBeFormatted = valueToBeFormatted.trim();

            if (!isDefaultValue) {
                let inputWithoutApostrophe = this.removeSpecificLeadingCharacter(valueToBeFormatted, "'");

                while (inputWithoutApostrophe.length > 0 && inputWithoutApostrophe[0] === "'") {
                    inputWithoutApostrophe = this.removeSpecificLeadingCharacter(inputWithoutApostrophe, "'");
                }

                if (isStringNumber(inputWithoutApostrophe)) {
                    return `'${valueToBeFormatted}`;
                }
            }
        }

        // if the input is a number or if this is a new submission with a string number input
        if (
            formatString &&
            valueToBeFormatted &&
            (typeof valueToBeFormatted === 'number' || (isDefaultValue && isStringNumber(valueToBeFormatted)))
        ) {
            const numberFormatObject = smartsheetFormatUtility.getNumberFormatObject(formatString);

            // Update to handle percent values
            if (numberFormatObject && numberFormatObject.numberFormat === NumberFormatType.percent) {
                return safeMultiplyByPowerOf10(valueToBeFormatted, 2);
            }
        }

        return valueToBeFormatted;
    };

    /**
     * Takes in a Smartsheet API Format String and returns the corresponding SmartsheetFormatObject
     */
    public getFormatObjectFromString = (formatString: Format): SmartsheetFormatObject => {
        const formatObject: SmartsheetFormatObject = {
            fontFamily: undefined,
            fontSize: undefined,
            bold: undefined,
            italic: undefined,
            underline: undefined,
            strikethrough: undefined,
            horizontalAlign: undefined,
            verticalAlign: undefined,
            textColor: undefined,
            backgroundColor: undefined,
            taskbarColor: undefined,
            currency: undefined,
            decimalCount: undefined,
            thousandSeparator: undefined,
            numberFormat: undefined,
            textWrap: undefined,
            dateFormat: undefined,
        };

        if (typeof formatString !== 'string') {
            return formatObject;
        }

        // Parse the string parts into their corresponding object values
        const formatStringValues = formatString.split(',');
        const formatMapKeys = Object.keys(this.formatMap);

        formatStringValues.forEach((stringValue: string, index: number) => {
            const formatMapKey = formatMapKeys[index];

            if (stringValue && this.formatMap[formatMapKey]) {
                formatObject[formatMapKey] = this.formatMap[formatMapKey].lookup[stringValue];
            }
        });
        return formatObject;
    };

    /**
     * Takes in a Smartsheet Format Object and returns the corresponding Smartsheet API String
     */
    public getFormatStringFromObject = (formatObject: SmartsheetFormatObject): string => {
        // Convert the enums to their equivalent string value
        const formatStringArray = Object.keys(formatObject).map((key) => {
            const objectValue = formatObject[key];
            if (objectValue) {
                return this.formatMap[key].enumSet[objectValue];
            } else {
                return '';
            }
        });

        const formatString = formatStringArray.join(',');

        return formatString;
    };

    /**
     * Helper Method: Checks that the given api format string is properly formatted
     * by performing a split on commas, counting the parts, and checking
     * that each element is a numeric value match or an empty string.
     *
     * Use when constructing/modifying API strings directly from the application.
     */
    public isValidAPIFormatString = (formatString: string): boolean => {
        const expectedPartsLength = EXPECTED_FORMAT_STRING_PARTS;
        const formatArray = formatString.split(',');

        // return true if the string is properly formatted
        return (
            formatArray.length === expectedPartsLength &&
            formatArray.every(
                (x) =>
                    RegExp(/^\d+$/).test(x) || // checks if the number is a positive integer
                    x.length === 0
            ) // or if it is an empty string
        );
    };

    /**
     * Takes in a Smartsheet format string and conditionalFormat string on both the cell
     * and row, and returns a consolidated format string (conditionals take precedence)
     */
    public getConsolidatedFormatString = (
        formatString?: Format,
        conditionalFormatStringOnCell?: Format,
        conditionalFormatStringOnRow?: Format
    ): Format | undefined => {
        // If no conditionalFormat provided on cell, use the conditionalFormat on the row if available
        const conditionalFormatString = conditionalFormatStringOnCell || conditionalFormatStringOnRow;

        // For each format prop, use conditional if defined, otherwise default to formatString
        if (typeof conditionalFormatString === 'string' && conditionalFormatString && typeof formatString === 'string' && formatString) {
            const conditionalFormatArray = conditionalFormatString.split(',');
            const formatArray = formatString.split(',');

            return conditionalFormatArray.map((format, index) => format || formatArray[index]).join(',');
        } else {
            return conditionalFormatString || formatString;
        }
    };

    /**
     * Helper Method: Generates reverse lookup object from the format enum sets
     */
    private generateFormatMap = (): void => {
        // Explicitly defines which format enum set goes with which format map key and stores for instance wide lookup
        this.formatMap = {
            [FormatMapKeys.fontFamily]: { enumSet: fontFamily, lookup: this.generateLookupFromEnum(fontFamily) },
            [FormatMapKeys.fontSize]: { enumSet: fontSize, lookup: this.generateLookupFromEnum(fontSize) },
            [FormatMapKeys.bold]: { enumSet: appliedFormat, lookup: this.generateLookupFromEnum(appliedFormat) },
            [FormatMapKeys.italic]: { enumSet: appliedFormat, lookup: this.generateLookupFromEnum(appliedFormat) },
            [FormatMapKeys.underline]: { enumSet: appliedFormat, lookup: this.generateLookupFromEnum(appliedFormat) },
            [FormatMapKeys.strikethrough]: { enumSet: appliedFormat, lookup: this.generateLookupFromEnum(appliedFormat) },
            [FormatMapKeys.horizontalAlign]: { enumSet: horizontalAlign, lookup: this.generateLookupFromEnum(horizontalAlign) },
            [FormatMapKeys.verticalAlign]: { enumSet: verticalAlign, lookup: this.generateLookupFromEnum(verticalAlign) },
            [FormatMapKeys.textColor]: { enumSet: colorFormat, lookup: this.generateLookupFromEnum(colorFormat) },
            [FormatMapKeys.backgroundColor]: { enumSet: colorFormat, lookup: this.generateLookupFromEnum(colorFormat) },
            [FormatMapKeys.taskbarColor]: { enumSet: colorFormat, lookup: this.generateLookupFromEnum(colorFormat) },
            [FormatMapKeys.currencyFormat]: { enumSet: currencyFormat, lookup: this.generateLookupFromEnum(currencyFormat) },
            [FormatMapKeys.decimalCount]: { enumSet: decimalCount, lookup: this.generateLookupFromEnum(decimalCount) },
            [FormatMapKeys.thousandSeparator]: { enumSet: appliedFormat, lookup: this.generateLookupFromEnum(appliedFormat) },
            [FormatMapKeys.numberFormat]: { enumSet: numberFormat, lookup: this.generateLookupFromEnum(numberFormat) },
            [FormatMapKeys.textWrap]: { enumSet: appliedFormat, lookup: this.generateLookupFromEnum(appliedFormat) },
            [FormatMapKeys.dateFormat]: { enumSet: dateFormatSMAR, lookup: this.generateLookupFromEnum(dateFormatSMAR) },
        };
    };

    /**
     * Helper Method: Generates lookup map for an individual enum set
     */
    private generateLookupFromEnum = (enumSet: any): object => {
        const returnObject: any = {};
        Object.keys(enumSet).forEach((key: string) => (returnObject[enumSet[key]] = key));
        return returnObject;
    };

    /**
     * Helper Method: Removes specific leading character if it's present
     */
    private removeSpecificLeadingCharacter = (input: string, character: string): string => {
        return input.length && input[0] === character ? input.slice(1) : input;
    };
}

export const smartsheetFormatUtility = SmartsheetFormatUtility.getInstance();
