import styled from '@emotion/styled';
import { Modal, ModalBody, ModalFooter, ModalFooterButton, ModalHeader } from '@smartsheet/lodestar-core/';
import { HandleContacts } from '../../../../common/classes';
import { RowFieldFactory } from '../../../../common/classes/RowField';
import { AsyncStatus, ColumnType } from '../../../../common/enums';
import * as dv from '../../../../common/interfaces';
import { FormFieldInterface, Image, ImageUrlDto as ImageUrl, SmartsheetUser } from '../../../../common/interfaces';
import { clearCellImageFields } from '../../../../common/interfaces/FormFieldInterface';
import { areArraysEqual, isMultiPicklistObjectValue, isNotUndefined } from '../../../../common/utils';
import * as React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import 'reflect-metadata';
import { createStructuredSelector } from 'reselect';
import alertIcon from '../../../../assets/images/alert/icon-info-circle-bluebigger.svg';
import * as gsFormattingUtility from '../../../../common/utils/GsFormattingUtility';
import FormFieldWrapper from '../../../../components/FormFieldWrapper/FormFieldWrapper';
import { Size } from '../../../../components/Spinner';
import withSetAppError, { WithSetAppErrorProps } from '../../../../components/hoc/WithSetAppError';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../../../language-elements/withLanguageElementsHOC';
import { StoreState } from '../../../../store';
import { Actions as ImageActions } from '../../../../store/images/Actions';
import { imageExpirationsSelector, imageThumbnailsSelector, imageUrlsSelector } from '../../../../store/images/Selectors';
import { SubmittedForm } from '../../SubmittedForm';
import { Actions } from '../Actions';
import { CellImages } from '../CellImage/CellImages';
import './DetailsData.css';
import { DetailsDataError } from './DetailsDataError';
import DetailsDataFooter from './DetailsDataFooter';
import { getCellObjectValueFromField } from './DetailsDataUtils';
import { SavingData } from './SavingData';

export interface OwnProps {
    form: dv.FormFieldInterface[];
    smartsheetUsers: dv.IPaginatedResult<SmartsheetUser>;
    viewId: string;
    rowId?: string;
    showModal: boolean;
    onChangeIsDirty: (isDirty: boolean) => void;
    onSave: (submittedForm: SubmittedForm, cellImages: CellImages) => void;
    saveStatus?: AsyncStatus;
    submittedForm?: SubmittedForm;
    isNewSubmission: boolean;
    getRowData: () => void;
}

interface DispatchProps {
    closeModalDetailsPanel: () => Actions;
    clearImageUrl: (imageId: ImageUrl['imageId']) => void;
    clearThumbnailUrl: (imageId: ImageUrl['imageId']) => void;
}

interface StateProps {
    imageUrls: Map<ImageUrl['imageId'], ImageUrl>;
    imageThumbnails: Map<ImageUrl['imageId'], ImageUrl>;
    imageExpirations: Map<ImageUrl['url'], Date>;
}

export type DetailsDataProps = OwnProps & WithSetAppErrorProps & StateProps & DispatchProps & LanguageElementsProp;

export interface State {
    currentData: SubmittedForm;
    isDirty: boolean;
    isValid: boolean;
}

export const DETAILS_PANEL_HEADER_HEIGHT = 72;

export class DetailsDataLegacy extends React.Component<DetailsDataProps> {
    public state: State;
    private handleContacts: HandleContacts;

    public detailsDataRef = React.createRef<HTMLDivElement>();

    // TODO: What do we show to users if they return to a modified row?
    private readonly cellImages: CellImages = {
        attachedForUpload: new Map<FormFieldInterface['columnId'], File>(),
        altText: new Map<FormFieldInterface['columnId'], string>(),
        scheduledForRemoval: new Map<FormFieldInterface['columnId'], Image>(),
    };

    public constructor(props: DetailsDataProps) {
        super(props);
        this.handleContacts = new HandleContacts();

        if (this.props.saveStatus === AsyncStatus.ERROR || this.props.saveStatus === AsyncStatus.NOT_STARTED) {
            // When a user returns to a row containing a save error or an existing upsert is in progress, show the submitted form
            const currentForm = this.getFormFieldsObject();

            this.state = {
                currentData: { ...currentForm, ...this.props.submittedForm },
                isDirty: true,
                isValid: true,
            };
        } else {
            this.state = this.getInitialState();
        }
    }

    private FullWidthFooter = styled(ModalFooter)`
        & > div {
            width: 100%;
        }
    `;
    private SpecialFooterButton = styled(ModalFooterButton)`
        flex: 0 0 auto;
    `;
    private LeftSideButton = styled(this.SpecialFooterButton)`
        margin-right: auto;
        margin-left: 0;
    `;

    private getModalContent = (isValid: boolean) => {
        const alertImg = <img src={alertIcon} alt="alert" />;
        if (!isValid) {
            return (
                <>
                    <ModalHeader
                        title={this.props.languageElements.DETAILS_DATA_MODAL_UNSAVED_INVALID_CHANGES_TITLE}
                        titleIcon={alertImg}
                        onCloseRequested={this.props.closeModalDetailsPanel}
                    />
                    <ModalBody>
                        <p>{this.props.languageElements.INVALID_CHANGES_MESSAGE}</p>
                    </ModalBody>
                    <ModalFooter>
                        <ModalFooterButton
                            onClick={() => {
                                this.props.closeModalDetailsPanel();
                            }}
                        >
                            {this.props.languageElements.BUTTON_TEXT_OK}
                        </ModalFooterButton>
                    </ModalFooter>
                </>
            );
        }
        return (
            <>
                <ModalHeader
                    title={this.props.languageElements.DETAILS_DATA_MODAL_UNSAVED_CHANGES_TITLE}
                    titleIcon={alertImg}
                    onCloseRequested={this.props.closeModalDetailsPanel}
                />
                <ModalBody>
                    <p>{this.props.languageElements.DETAILS_DATA_MODAL_UNSAVED_CHANGES_MESSAGE}</p>
                </ModalBody>
                <this.FullWidthFooter>
                    <this.LeftSideButton
                        appearance={'secondary'}
                        onClick={() => {
                            this.props.closeModalDetailsPanel();
                        }}
                    >
                        {this.props.languageElements.BUTTON_TEXT_CANCEL}
                    </this.LeftSideButton>
                    <this.SpecialFooterButton
                        appearance={'secondary-destructive'}
                        onClick={() => {
                            this.handleCancel();
                            this.props.closeModalDetailsPanel();
                        }}
                    >
                        {this.props.languageElements.BUTTON_TEXT_DISCARD_CHANGES}
                    </this.SpecialFooterButton>
                    <this.SpecialFooterButton onClick={this.handleSave}>{this.props.languageElements.BUTTON_TEXT_SAVE}</this.SpecialFooterButton>
                </this.FullWidthFooter>
            </>
        );
    };

    public render = () => (
        <>
            <div className="details-data">
                <div ref={this.detailsDataRef} className={this.getDetailsDataClassNames()}>
                    {this.state.currentData && this.renderFormFields(this.state.currentData)}
                </div>

                <DetailsDataError getRowData={this.props.getRowData} isNewSubmission={this.props.isNewSubmission} />

                <DetailsDataFooter
                    isDirty={this.state.isDirty}
                    isValid={this.state.isValid && this.props.saveStatus !== AsyncStatus.ERROR}
                    isSaving={this.props.saveStatus === AsyncStatus.NOT_STARTED}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                />
            </div>

            <SavingData rowId={this.props.rowId} saveStatus={this.props.saveStatus} isNewSubmission={this.props.isNewSubmission} />

            <Modal isOpen={this.props.showModal} onCloseRequested={this.props.closeModalDetailsPanel} width={Size.MEDIUM}>
                {this.getModalContent(this.state.isValid)}
            </Modal>
            <Prompt
                message={this.props.languageElements.ADMIN_PANEL_PROMPT_UNSAVED_CHANGES_MESSAGE}
                when={!this.props.showModal && this.state.isDirty}
            />
        </>
    );

    public componentDidUpdate(prevProps: DetailsDataProps): void {
        const selectedRowChanged = prevProps.rowId != null && this.props.rowId !== prevProps.rowId;
        const saveStatusChanged = this.props.saveStatus != null && this.props.saveStatus !== prevProps.saveStatus;

        if (selectedRowChanged || saveStatusChanged) {
            // For save status of error or in progress, always use the submitted form data
            if (this.props.saveStatus === AsyncStatus.ERROR || this.props.saveStatus === AsyncStatus.NOT_STARTED) {
                const currentForm = this.getFormFieldsObject();
                const submittedForm = this.formatSubmittedForm(this.props.submittedForm);

                this.setState({
                    currentData: { ...currentForm, ...submittedForm },
                    isDirty: true,
                    isValid: true,
                });
            } else if (this.props.saveStatus === AsyncStatus.IN_PROGRESS) {
                this.setState({ isDirty: false }, () => this.props.onChangeIsDirty(false));
            } else if (this.state?.isDirty) {
                return;
            } else {
                this.setState(this.getInitialState());
            }
        }
    }

    public handleChange = (columnId: number, value: any): void => {
        const currentData: SubmittedForm = { ...this.state.currentData };

        // A value of 'undefined' is not supported for representing an empty value for a row field, if 'undefined' was somehow passed from
        // the DV API to SMAR API, an exception would be thrown. Instead, 'null' should be used to represent an empty value
        currentData[columnId] = value === undefined ? null : value;

        const isDirty = this.checkFormFieldsAreDirty(currentData);

        this.setState(
            {
                currentData,
                isDirty,
                isValid: this.checkFormFieldsAreValid(currentData),
            },
            () => this.props.onChangeIsDirty(isDirty)
        );
    };

    /**
     * Reset the form to its original state prior to the user making updates.
     */
    public handleCancel = (): void => {
        if (this.props.rowId != null && this.props.saveStatus === AsyncStatus.ERROR) {
            // When a user discards the changes for a failed upsert request, reset the row upsert request property. This causes
            // the error message to disappear on the details panel and the view screen. No need to set state because the resetViewRowUpsertRequest
            // will trigger this component to update that in turn causes the state to be set

            // TODO: Figure out what to do in new version
            // this.props.resetViewRowUpsertRequest(this.props.viewId, this.props.rowId);

            this.props.onChangeIsDirty(false);
        } else {
            // Clear the map that tracks cell images attached for upload.
            this.cellImages.attachedForUpload.clear();

            // Clear the map that tracks altText changes.
            this.cellImages.altText.clear();

            // Before clearing the map that tracks what cell images were marked for removal,
            // we need to restore the image property for those fields.
            this.props.form.forEach((field) => {
                const image = this.cellImages.scheduledForRemoval.get(field.columnId);
                if (image) {
                    field.image = image;
                    this.setImageUrlFields(field);
                    this.cellImages.scheduledForRemoval.delete(field.columnId);
                }
            });

            // Field changes are tracked in the component's state. Reset to the initial state.
            this.setState(this.getInitialState(), () => {
                this.props.onChangeIsDirty(false);
            });
        }
    };

    /**
     * Save details data for row and sheet
     */
    public handleSave = () => {
        if (!this.state.isDirty) {
            return;
        }

        this.props.closeModalDetailsPanel();
        this.props.onSave(this.state.currentData, this.cellImages);
    };

    private handleInsertImage = (field: FormFieldInterface, file: File) => {
        // Attach the file for upload.
        this.cellImages.attachedForUpload.set(field.columnId, file);

        // Attaching a new cell image will automatically overwrite other cell images.
        // We no longer need to track pre-existing cell images that users may have previously cleared.
        this.cellImages.scheduledForRemoval.delete(field.columnId);

        // Use the file's name as the initial altText value.  User's might change it before saving row changes.
        this.cellImages.altText.set(field.columnId, file.name);
        field.image = { altText: file.name };

        // Reset the tracked cell object value since cell images are tracked differently.
        this.handleChange(field.columnId, getCellObjectValueFromField(field, false));

        this.setState({ isDirty: true }, () => this.props.onChangeIsDirty(true));
    };

    private handleClearContent = (field: dv.FormFieldInterface): void => {
        // If the field contains a previously uploaded cell image, track the pending removal
        if (field.image) {
            this.cellImages.scheduledForRemoval.set(field.columnId, field.image);
        }

        // Remove any (not yet uploaded) cell image that had been attached.
        this.cellImages.attachedForUpload.delete(field.columnId);

        // Clean up any cell image related properties from the field.
        clearCellImageFields(field);

        this.handleChange(field.columnId, null);
    };

    private handleAltTextChange = (field: FormFieldInterface, altText: string) => {
        const changed = field.image!.altText !== altText;
        if (changed) {
            this.cellImages.altText.set(field.columnId, altText);
        } else {
            this.cellImages.altText.delete(field.columnId);
        }
        const isDirty = this.checkFormFieldsAreDirty(this.state.currentData);
        this.setState({ isDirty }, () => this.props.onChangeIsDirty(isDirty));
    };

    private getFormFieldWrapper(field: dv.FormFieldInterface, index: number, currentData: SubmittedForm) {
        const { columnId } = field;
        const value: dv.CellObjectValue | dv.ContactObjectValue[] = currentData[columnId];
        return (
            <FormFieldWrapper
                key={columnId}
                inputIndex={index}
                field={field}
                value={value}
                hyperlink={field.hyperlink}
                columnId={columnId}
                cellImages={this.cellImages}
                onInsertImage={this.handleInsertImage}
                onSetImageUrlFields={this.setImageUrlFields}
                onAltTextChange={this.handleAltTextChange}
                onClearContent={this.handleClearContent}
                onChange={this.handleChange}
                isSettingsMode={false}
                isNewSubmission={this.props.isNewSubmission}
                onGetContactListValue={this.handleContacts.getContactListValue}
                onGetMultiContactsIfValid={this.handleContacts.getMultiContactsIfValid}
                type={field.type}
                placeholder={field.placeholder}
                customLabel={field.customLabel}
                displayValue={field.displayValue}
                format={field.format}
                required={field.required}
                multiline={field.multiline}
                description={field.description}
                objectValue={field.objectValue}
                title={field.title}
                options={field.options}
                symbol={field.symbol}
                readOnly={field.readOnly}
                systemColumnType={field.systemColumnType}
                contactOptions={field.contactOptions}
                checked={field.checked}
                validation={field.validation}
                image={field.image}
                thumbnailUrl={field.thumbnailUrl}
                detailsDataRef={this.detailsDataRef}
            />
        );
    }

    private renderFormFields = (currentData: SubmittedForm) => {
        return this.props.form
            .map((field: dv.FormFieldInterface, index: number) => this.getFormFieldWrapper(field, index, currentData))
            .filter(isNotUndefined);
    };

    private getDetailsDataClassNames = (): string => {
        let classNames = 'details-list';
        if (this.state.isDirty) {
            classNames += ' open';
        }
        if (this.props.saveStatus === AsyncStatus.ERROR) {
            classNames += ' save-row-error';
        }
        return classNames;
    };

    // TODO: We need different case for MULTI because the return statement below compares an objectValue to a value prop
    //       so it always returns false. (may want to compare the values prop of objectValue instead of objVal)
    //       Maybe use Immutable's List for checking - see private isListDirty in Admin/index for example.
    private checkFormFieldIsDirty = (formField: dv.FormFieldInterface, currentData: SubmittedForm): boolean => {
        let currentColumnValue = currentData[formField.columnId];

        if (
            formField.type === ColumnType.TEXT_NUMBER &&
            formField.value != null &&
            (typeof currentColumnValue === 'string' || typeof currentColumnValue === 'number')
        ) {
            currentColumnValue = gsFormattingUtility.getNumberFromTrimmedInputAndFormatStringAll(currentColumnValue, formField.format, false);
        } else if (formField.type === ColumnType.CHECKBOX && !currentColumnValue) {
            return Boolean(formField.value);
        } else if (
            formField.type === ColumnType.MULTI_PICKLIST &&
            isMultiPicklistObjectValue(currentColumnValue) &&
            isMultiPicklistObjectValue(formField.objectValue)
        ) {
            return !areArraysEqual(formField.objectValue.values, currentColumnValue.values);
        }

        return formField.value !== currentColumnValue;
    };

    private checkFormFieldsAreDirty = (currentData: SubmittedForm): boolean => {
        return (
            this.cellImages.attachedForUpload.size > 0 ||
            this.cellImages.scheduledForRemoval.size > 0 ||
            this.cellImages.altText.size > 0 ||
            this.props.form.some((formField: dv.FormFieldInterface) => this.checkFormFieldIsDirty(formField, currentData))
        );
    };

    private checkFormFieldsAreValid = (currentData: SubmittedForm): boolean => {
        return this.props.form.every((formField: dv.FormFieldInterface) => {
            if (!currentData.hasOwnProperty(formField.columnId)) {
                return true;
            }

            let currentValue = currentData[formField.columnId];

            // Ignore empty white space input before checking if req'd field is valid
            if (typeof currentValue === 'string') {
                currentValue = currentValue.trim();
            }

            // Check that all required fields are filled (used == to catch undefined and null, but ignore booleans)
            if (formField.required && (currentValue == null || currentValue === '')) {
                // When a user attaches an image, the "value" of the field will be cleared out.
                // If the field contains an image along with some alt text, then consider it valid.
                const containsImage = this.cellImages.attachedForUpload.has(formField.columnId);
                const imageHasAltText = this.cellImages.altText.has(formField.columnId);
                return containsImage && imageHasAltText;
            }

            if (!this.checkFormFieldIsDirty(formField, currentData)) {
                return true;
            }

            let value;
            let objectValue;

            switch (formField.type) {
                case ColumnType.MULTI_PICKLIST:
                case ColumnType.MULTI_CONTACT_LIST:
                    objectValue = currentData[formField.columnId] === undefined ? null : (currentData[formField.columnId] as dv.GenericObjectValue);
                    break;
                default:
                    value = currentData[formField.columnId] === undefined ? null : currentData[formField.columnId];
            }

            const rowField = RowFieldFactory.create(formField, { value, objectValue });
            return rowField.isValid;
        });
    };

    private getFormFieldsObject = (): SubmittedForm => {
        const formFieldsObject: SubmittedForm = {};
        this.props.form.forEach((field) => (formFieldsObject[field.columnId] = getCellObjectValueFromField(field, this.props.isNewSubmission)));

        return formFieldsObject;
    };

    // submitted form is the raw data that is being updated, but we need to format value for use in display
    private formatSubmittedForm = (form: SubmittedForm | undefined) => {
        if (form) {
            for (const key in form) {
                if (form.hasOwnProperty(key)) {
                    const field = this.props.form.filter((f) => f.columnId === Number(key))[0];
                    if (field.format && typeof form[key] === 'number') {
                        form[key] = gsFormattingUtility.getFormattedValueForEditAll(Number(form[key]!), field.format);
                    }
                }
            }
        }
        return form;
    };

    private setImageUrlFields = (field: FormFieldInterface) => {
        if (field.image && field.image.id) {
            field.thumbnailUrl = this.getCurrentImageUrl(field.image.id, this.props.imageThumbnails, this.props.clearThumbnailUrl);
            field.imageUrl = this.getCurrentImageUrl(field.image.id, this.props.imageUrls, this.props.clearImageUrl);
        }
    };

    private getCurrentImageUrl = (imageId: string, imageUrls: Map<ImageUrl['imageId'], ImageUrl>, expiredUrlHandler: (imageId: string) => void) => {
        const imageUrl = imageUrls.get(imageId);
        if (!imageUrl) {
            return;
        }

        // Image urls are temporary, so we need to make sure that the url has not expired.
        const urlExpiration = this.props.imageExpirations.get(imageUrl.url!);
        const isExpired = urlExpiration && new Date() > urlExpiration;
        if (isExpired) {
            expiredUrlHandler(imageId);
            return;
        }

        return imageUrl;
    };

    private getInitialState = () => {
        return {
            currentData: this.getFormFieldsObject(),
            isDirty: false,
            isValid: true,
        };
    };
}

const mapState = createStructuredSelector<StoreState, StateProps>({
    imageUrls: imageUrlsSelector,
    imageThumbnails: imageThumbnailsSelector,
    imageExpirations: imageExpirationsSelector,
});

const mapDispatch = {
    closeModalDetailsPanel: Actions.closeModalDetailsPanel,
    clearImageUrl: ImageActions.clearImageUrl,
    clearThumbnailUrl: ImageActions.clearThumbnailUrl,
};

export default withLanguageElementsHOC(withSetAppError(connect(mapState, mapDispatch)(DetailsDataLegacy)));
