import { AsyncStatus } from '../../common/enums';
import * as dv from '../../common/interfaces';
import { AxiosError } from 'axios';
import { AsyncResult } from '../../store';
import { Actions, ActionTypes } from './Actions';

export interface ViewState {
    viewData: AsyncResult<dv.ViewData, AxiosError>;
    config: AsyncResult<dv.ViewWithOwnerAndUserDetails, AxiosError>;
}

const initialState: ViewState = {
    viewData: {
        status: AsyncStatus.NOT_STARTED,
    },
    config: {
        status: AsyncStatus.NOT_STARTED,
    },
};

const reducer = (state: ViewState = initialState, action: Actions): ViewState => {
    switch (action.type) {
        case ActionTypes.RESET_VIEW_STATE:
            return { ...initialState };
        // View data
        case ActionTypes.FETCH_VIEW_DATA:
            return { ...state, viewData: { status: AsyncStatus.IN_PROGRESS } };

        case ActionTypes.FETCH_VIEW_DATA_ALL:
            return { ...state, viewData: { status: AsyncStatus.PARTIAL, data: action.payload.viewData } };

        case ActionTypes.STORE_VIEW_DATA:
            return { ...state, viewData: { status: AsyncStatus.DONE, data: action.payload.viewData } };

        case ActionTypes.FETCHING_ERROR_VIEW_DATA:
            return { ...state, viewData: { status: AsyncStatus.ERROR, error: action.payload.error } };

        // Container links
        case ActionTypes.STORE_CONTAINER_LINKS: {
            const { viewId, containerLinkMap } = action.payload;

            if (!isMatchingViewId(state, viewId) || state.viewData.status !== AsyncStatus.DONE) {
                break;
            }
            const updatedViewData = { ...state.viewData.data };
            updatedViewData.rows = updatedViewData.rows.map((row) => {
                const updatedRow = { ...row };
                updatedRow.cells = updatedRow.cells?.map((cell) => {
                    if (cell.containerId && containerLinkMap.has(cell.containerId)) {
                        const url = containerLinkMap.get(cell.containerId) ?? '-1';
                        return { ...cell, hyperlink: { url } };
                    }
                    return cell;
                });
                return updatedRow;
            });
            return { ...state, viewData: { ...state.viewData, data: updatedViewData } };
        }

        case ActionTypes.FETCHING_ERROR_CONTAINER_LINKS: {
            const { viewId, containerIds } = action.payload;

            if (!isMatchingViewId(state, viewId) || state.viewData.status !== AsyncStatus.DONE) {
                break;
            }
            const updatedViewData = { ...state.viewData.data };
            updatedViewData.rows = updatedViewData.rows.map((row) => {
                const updatedRow = { ...row };
                updatedRow.cells = updatedRow.cells?.map((cell) => {
                    if (cell.containerId && containerIds.includes(cell.containerId)) {
                        const url = '-1';
                        return { ...cell, hyperlink: { url } };
                    }
                    return cell;
                });
                return updatedRow;
            });
            return { ...state, viewData: { ...state.viewData, data: updatedViewData } };
        }

        // Grid row
        case ActionTypes.UPDATE_GRID_ROW: {
            const { viewId, row: updatedRow } = action.payload;

            if (!isMatchingViewId(state, viewId) || state.viewData.status !== AsyncStatus.DONE) {
                break;
            }

            const viewData = state.viewData;
            const updatedRows = state.viewData.data.rows.map((row) => {
                if (row.id === updatedRow.id) {
                    return updatedRow;
                }
                return row;
            });

            return { ...state, viewData: { ...viewData, data: { ...viewData.data, rows: updatedRows } } };
        }
        case ActionTypes.ADD_GRID_ROW: {
            const { viewId } = action.payload;

            if (!isMatchingViewId(state, viewId) || state.viewData.status !== AsyncStatus.DONE) {
                break;
            }

            const viewData = state.viewData;
            const rows = [...viewData.data.rows, action.payload.row];

            return { ...state, viewData: { ...viewData, data: { ...viewData.data, rows } } };
        }
        case ActionTypes.FETCHING_ERROR_GRID_ROW:
        case ActionTypes.REMOVE_GRID_ROW: {
            const { viewId, rowId } = action.payload;

            if (!isMatchingViewId(state, viewId) || state.viewData.status !== AsyncStatus.DONE) {
                break;
            }

            const viewData = state.viewData;
            const rowIdToRemove = parseInt(rowId, 10);
            const rows = viewData.data.rows.filter((row) => row.id !== rowIdToRemove);

            return { ...state, viewData: { ...viewData, data: { ...viewData.data, rows } } };
        }

        // Config
        case ActionTypes.FETCH_VIEW_CONFIG:
            return { ...state, config: { status: AsyncStatus.IN_PROGRESS } };

        case ActionTypes.STORE_CONFIG:
            return { ...state, config: { status: AsyncStatus.DONE, data: action.payload.config } };

        case ActionTypes.FETCHING_ERROR_VIEW_CONFIG:
            return { ...state, config: { status: AsyncStatus.ERROR, error: action.payload.error } };
    }

    return state;
};

const isMatchingViewId = (state: ViewState, viewId: string): boolean => {
    return state.config.status === AsyncStatus.DONE && state.config.data.id === viewId;
};

export default reducer;
