import { SupportedLocales } from '@smartsheet/lodestar-core/dist/esm/i18n/constants';
import { createSelector } from 'reselect';
import { StoreState } from '../../store';
import { EligibilityStatus } from './interfaces/EligibilityStatusEnum';
import { User } from './interfaces/User';

export const isAuthenticatedSelector = (state: StoreState) => state.auth.get('isAuthenticated');

export const userSelector = createSelector(
    (state: StoreState) => state.auth.get('user'),
    (user) => user.toJS() as User
);
export const isUserLicensedSelector = createSelector(userSelector, (user) => user.isUserLicensed!);
export const userEligibilitySelector = createSelector(userSelector, (user) => user.eligibility!);
export const isUserEligibleOrInTrial = createSelector(
    userSelector,
    (user) => user.eligibility !== undefined && [EligibilityStatus.PLAN_IS_ELIGIBLE, EligibilityStatus.ALREADY_IN_TRIAL].includes(user.eligibility)
);
export const userHasLicenseAccessSelector = createSelector(
    isUserLicensedSelector,
    isUserEligibleOrInTrial,
    (userLicensed, userEligibleOrInTrial) => userLicensed || userEligibleOrInTrial
);
export const isUserSystemAdmin = createSelector(userSelector, userHasLicenseAccessSelector, (user, isLicensed) => user.admin! && isLicensed);
export const userEmailSelector = createSelector(userSelector, (user) => user.email);
export const profilePicUrlSelector = createSelector(userSelector, (user) => user.profileImgUrl);
export const userFirstLastNameSelector = createSelector(userSelector, (user) => {
    return user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : '';
});
export const userLocaleSelector = createSelector(userSelector, (user) => user.locale!);
export const shortLocaleSelector = createSelector(userSelector, (user) => user.locale!.slice(0, 2) as SupportedLocales);
export const userTimeZoneSelector = createSelector(userSelector, (user) => user.timeZone!);
