import { NumberSize, Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { PureBaseComponent } from '../Base';
import './RSidePanel.css';

const standardPanelWidth = 400;
const maxPanelWidth = 1000;

interface Props {
    controlId: string;
    sidePanelOpened: boolean;
    onClick: () => void;
    inIframe: boolean;
}

interface State {
    minPanelWidth: number;
    maxPanelWidth: number;
    currentPanelWidth: number;
}

// Note: For testing without the connect HOC we export the RSidePanel here
export class RSidePanel extends PureBaseComponent<Props> {
    public state: State;

    public constructor(props: Props) {
        super(props);
        const minPanelWidth = this.getMinPanelWidth();

        this.state = {
            minPanelWidth,
            maxPanelWidth: this.getMaxPanelWidth(),
            currentPanelWidth: Number(localStorage.getItem('detailsPanelWidth')) || minPanelWidth,
        };
    }

    public render(): JSX.Element {
        return (
            <CSSTransition
                in={this.props.sidePanelOpened}
                timeout={150}
                classNames={{
                    enter: 'animateRSidePanelEnter',
                    enterActive: 'animateRSidePanelEnterActive',
                    exit: 'animateRSidePanelLeave',
                    exitActive: 'animateRSidePanelLeaveActive',
                }}
                unmountOnExit={true}
            >
                <div data-client-id={this.props.controlId} className={`r-side-panel ${this.props.inIframe ? 'in-iframe' : ''}`}>
                    <Resizable
                        defaultSize={{
                            width: this.state.currentPanelWidth,
                            height: 'auto',
                        }}
                        minWidth={this.state.minPanelWidth}
                        maxWidth={this.state.maxPanelWidth}
                        onResizeStop={this.handleResize}
                        enable={{
                            top: false,
                            right: false,
                            bottom: false,
                            left: true,
                            topRight: false,
                            bottomRight: false,
                            bottomLeft: false,
                            topLeft: false,
                        }}
                        style={{ display: 'flex' }}
                    >
                        {this.props.children}
                    </Resizable>
                </div>
            </CSSTransition>
        );
    }

    public componentDidMount(): void {
        window.addEventListener('resize', this.handleWindowResize.bind(this));
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleWindowResize.bind(this));
    }

    public handleResize = (e: MouseEvent | TouchEvent, direction: Direction, ref: HTMLDivElement, delta: NumberSize) => {
        const currentPanelWidth = this.state.currentPanelWidth + delta.width;
        localStorage.setItem('detailsPanelWidth', currentPanelWidth.toString());
        this.setState({ currentPanelWidth });
    };

    private handleWindowResize = (): void => {
        let currentPanelWidth = this.state.currentPanelWidth;
        const newMaxPanelWidth = this.getMaxPanelWidth();
        const newMinPanelWidth = this.getMinPanelWidth();

        if (this.state.currentPanelWidth > newMaxPanelWidth) {
            currentPanelWidth = newMaxPanelWidth;
        }

        if (this.state.currentPanelWidth < newMinPanelWidth) {
            currentPanelWidth = newMinPanelWidth;
        }

        this.setState({ maxPanelWidth: newMaxPanelWidth, currentPanelWidth, minPanelWidth: newMinPanelWidth });
    };

    private getMaxPanelWidth = (): number => {
        return Math.min(window.innerWidth, maxPanelWidth);
    };

    private getMinPanelWidth = (): number => {
        return Math.min(window.innerWidth, standardPanelWidth);
    };
}

export default RSidePanel;
