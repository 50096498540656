import { ViewQueryParamIncludes } from '../common/enums';
import { QueryStringKey } from '../common/enums/QueryStringKey.enum';
import {
    Attachment,
    Comment,
    CreateView,
    Discussion,
    FormFieldInterface,
    FormUpdateResult,
    HomeView,
    IFilter,
    Image,
    RequestTrackingInformationDto,
    Row,
    RowData,
    View,
    ViewData,
    ViewShareBasic,
    ViewShareResult,
    ViewSourceMetaData,
    ViewWithOwnerAndUserDetails,
} from '../common/interfaces';
import { buildUrl } from '../common/utils';
import { AxiosPromise } from 'axios';
import { ViewStatus } from '../common/enums/ViewStatus';
import axiosInstance from './Axios.instance';
import { PermalinkRequestItem, ResourcePermalink } from './interfaces/Permalink';

class ViewClient {
    public static getInstance(): ViewClient {
        if (!ViewClient.instance) {
            ViewClient.instance = new ViewClient();
        }
        return ViewClient.instance;
    }

    private static instance: ViewClient;
    private static url = '/views';

    private constructor() {}

    public async getViews(
        includeRecents = false,
        includeOwnerDetails = false,
        includeFavorites = false,
        includeOrg = false,
        includePinned = false
    ): Promise<HomeView[]> {
        const url: string = buildUrl(ViewClient.url, {
            queryParams: {
                [QueryStringKey.INCLUDE]: [
                    includeRecents ? ViewQueryParamIncludes.RECENTS : undefined,
                    includeOwnerDetails ? ViewQueryParamIncludes.OWNERDETAILS : undefined,
                    includeFavorites ? ViewQueryParamIncludes.FAVORITES : undefined,
                    includeOrg ? ViewQueryParamIncludes.ORG : undefined,
                    includePinned ? ViewQueryParamIncludes.PINNED : undefined,
                ],
            },
        });
        const response = await axiosInstance.get(url);
        return response.data;
    }

    public getView(
        viewId: string,
        includeConfig: boolean,
        includeShares: boolean,
        includeForm: boolean,
        includeFilters: boolean
    ): AxiosPromise<ViewWithOwnerAndUserDetails> {
        const includes: string[] = [];
        if (includeConfig) {
            includes.push('config');
        }
        if (includeShares) {
            includes.push('shares');
        }
        if (includeForm) {
            includes.push('form');
        }
        if (includeFilters) {
            includes.push('filters');
        }

        let queryParam = '';
        if (includes.length) {
            queryParam = `?include=${includes.join(',')}`;
        }

        return axiosInstance.get(`${ViewClient.url}/${viewId}${queryParam}`);
    }

    public getViewConfig(viewId: string): AxiosPromise<ViewWithOwnerAndUserDetails> {
        return this.getView(viewId, true, false, false, true);
    }

    public create(newView: CreateView): AxiosPromise<ViewWithOwnerAndUserDetails> {
        return axiosInstance.post(ViewClient.url, newView);
    }

    public update(viewId: string, updatedView: View): AxiosPromise<ViewWithOwnerAndUserDetails> {
        return axiosInstance.put(`${ViewClient.url}/${viewId}`, updatedView);
    }

    public updateViewStatus(viewIds: string[], viewStatus: ViewStatus): AxiosPromise<void> {
        return axiosInstance.patch(`${ViewClient.url}/status`, { viewIds, viewStatus });
    }

    public getSourceMetaData(viewId: string): AxiosPromise<ViewSourceMetaData> {
        return axiosInstance.get(`${ViewClient.url}/${viewId}/sourcemetadata`);
    }

    public updateShares(viewId: string, updatedViewShares: ViewShareBasic[]): AxiosPromise<ViewShareResult> {
        return axiosInstance.put(`${ViewClient.url}/${viewId}/shares`, { shares: updatedViewShares });
    }

    public getViewData(viewId: string, params: { page?: number; rowCount?: number }): AxiosPromise<ViewData> {
        return axiosInstance.get(`${ViewClient.url}/${viewId}/rows`, {
            params,
        });
    }

    public getContainerLinks(viewId: string, requestItems: PermalinkRequestItem[]): AxiosPromise<ResourcePermalink[]> {
        return axiosInstance.post(`${ViewClient.url}/${viewId}/containerlinks`, requestItems);
    }

    public getGridRow(viewId: string, rowId: string): AxiosPromise<Row> {
        return axiosInstance.get(`${ViewClient.url}/${viewId}/rows/${rowId}`);
    }

    public getViewRow(viewId: string, rowId: string, reportSheetId?: number): AxiosPromise<RowData> {
        return axiosInstance.get(`${ViewClient.url}/${viewId}/rows/${rowId}/details`, {
            params: {
                rsid: reportSheetId,
            },
        });
    }

    public async updateViewRow(
        viewId: string,
        rowId: string,
        updatedRowFields: FormFieldInterface[],
        reportSheetId?: number
    ): Promise<FormUpdateResult> {
        const payload = { form: updatedRowFields };
        const response = await axiosInstance.patch(`${ViewClient.url}/${viewId}/rows/${rowId}`, payload, {
            params: {
                rsid: reportSheetId,
            },
        });
        return response.data;
    }

    public async addDiscussion(viewId: string, rowId: number, text: string, reportSheetId?: number): Promise<Discussion> {
        const url = `${ViewClient.url}/${viewId}/rows/${rowId}/discussions`;

        const response = await axiosInstance.post<Discussion>(
            url,
            { text },
            {
                params: {
                    rsid: reportSheetId,
                },
            }
        );
        return response.data;
    }

    public async addComment(viewId: string, rowId: number, text: string, discussionId: number, reportSheetId?: number): Promise<Comment> {
        const url = `${ViewClient.url}/${viewId}/rows/${rowId}/discussions/${discussionId}/comments`;

        const response = await axiosInstance.post<Comment>(
            url,
            { text },
            {
                params: {
                    rsid: reportSheetId,
                },
            }
        );
        return response.data;
    }

    public async updateComment(viewId: string, rowId: number, commentId: number, text: string, reportSheetId?: number): Promise<Comment> {
        const url = `${ViewClient.url}/${viewId}/rows/${rowId}/comments/${commentId}`;
        const result = await axiosInstance.put<Comment>(
            url,
            { text },
            {
                params: {
                    rsid: reportSheetId,
                },
            }
        );
        return result.data;
    }

    public async deleteComment(viewId: string, rowId: number, commentId: number, reportSheetId?: number): Promise<void> {
        await axiosInstance.delete(`${ViewClient.url}/${viewId}/rows/${rowId}/comments/${commentId}`, {
            params: {
                rsid: reportSheetId,
            },
        });
    }

    public async deleteDiscussion(viewId: string, rowId: number, discussionId: number, reportSheetId?: number): Promise<void> {
        await axiosInstance.delete(`${ViewClient.url}/${viewId}/rows/${rowId}/discussions/${discussionId}`, {
            params: {
                rsid: reportSheetId,
            },
        });
    }

    public getViewRowForm(viewId: string): AxiosPromise<RowData> {
        return axiosInstance.get(`${ViewClient.url}/${viewId}/rowForm`);
    }

    public async insertViewRow(viewId: string, updatedRowFields: FormFieldInterface[]): Promise<FormUpdateResult> {
        const payload = { form: updatedRowFields };
        const response = await axiosInstance.post(`${ViewClient.url}/${viewId}/rows`, payload);
        return response.data;
    }

    public getRowAttachment(viewId: string, rowId: number, attachmentId: number, reportSheetId?: number): AxiosPromise<Attachment> {
        return axiosInstance.get(`${ViewClient.url}/${viewId}/rows/${rowId}/attachments/${attachmentId}`, {
            params: {
                rsid: reportSheetId,
            },
        });
    }

    public addRowAttachment(viewId: string, rowId: number, file: any, reportSheetId?: number): AxiosPromise<Attachment> {
        const formData = new FormData();
        formData.append('file', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'x-file-size': file.size,
            },
            params: {
                rsid: reportSheetId,
            },
        };
        return axiosInstance.post(`${ViewClient.url}/${viewId}/rows/${rowId}/attachments`, formData, config);
    }

    public async insertCellImage(
        viewId: string,
        rowId: number,
        columnId: number,
        file: File,
        altText?: string,
        reportSheetId?: number
    ): Promise<{ columnId: number; images: Image[] }> {
        const formData = new FormData();
        formData.append('file', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'x-file-size': file.size,
            },
            params: {
                rsid: reportSheetId,
                altText: altText || '',
            },
        };
        const response = await axiosInstance.post<Image[]>(
            `${ViewClient.url}/${viewId}/rows/${rowId}/columns/${columnId}/cellimages`,
            formData,
            config
        );
        return { columnId, images: response.data };
    }

    public addFilter(viewId: string, filter: IFilter): AxiosPromise<IFilter> {
        return axiosInstance.post(`${ViewClient.url}/${viewId}/filters`, filter);
    }

    public updateFilter(viewId: string, filter: IFilter): AxiosPromise<IFilter> {
        return axiosInstance.put(`${ViewClient.url}/${viewId}/filters/${filter.id || ''}`, filter);
    }

    public deleteFilter(viewId: string, filterId: string): AxiosPromise<IFilter> {
        return axiosInstance.delete(`${ViewClient.url}/${viewId}/filters/${filterId}`);
    }

    public async pollTrackingId(viewId: string, sheetId: number, trackingId: string): Promise<RequestTrackingInformationDto> {
        const response = await axiosInstance.get<RequestTrackingInformationDto>(`${ViewClient.url}/${viewId}/grid/${sheetId}/tracking/${trackingId}`);
        return response.data;
    }
}

const viewClient = ViewClient.getInstance();
export default viewClient;
